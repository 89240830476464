import {React,useEffect} from "react";
import "./Performance.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Performance() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="Performance">
        <div className="container">
          <div className="performance-heading">
            <h1 className="title">Performance</h1>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-12">
              <div className="main-content-holder">
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">May 2023</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Audited Financial <br /> Statements For FY <br />{" "}
                          2022-23
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">November 2022</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Half Year Interim <br /> Financial Results <br /> 2023
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">31 March 2022</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Financial <br /> Statements <br /> 2021-2022
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">31 March 2022</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Annual Financial <br /> Results 2021-2022
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">May 2023</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Annual Financial <br /> Results 2020-2021
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">31 March 2021</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Full Audited Annual <br /> Financial Statements <br />{" "}
                          2020-2021
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">17 November 2020</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Half Year Interim <br /> Financial Results <br />{" "}
                          2020-2021
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4">
                    <div className="file-box">
                      <div className="file">
                        <img
                          className="file-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/performance/file.png"
                          }
                          alt="file-img"
                        />
                      </div>
                      <div className="date-holder">
                        <p className="title">31 March 2022</p>
                      </div>
                      <div className="text-holder">
                        <p className="title">
                          Financial <br /> Statements
                        </p>
                      </div>
                      <div className="purple-read-more">
                        <Button className="purple-read-more-btn" id="btn-size">
                          More Info
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Performance;
