import {React, useEffect} from "react";
import "./Contact.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Contact Us</h1>
        </div>
      </section>

    
    <section className="main">
      <div className="container-fluid p-0">
        <section className="Contact">
          <div className="Contact-content">
          <div className="blue-bg">
          <img
                      className="contact-bg-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/contact/contact_bg.png"
                      }
                      alt="contact-bg-img"
                    />
          </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-5 col-lg-5 col-md-6">
                  <div className="img-holder">
                    <img
                      className="contact-img-1"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/contact/contact_img.png"
                      }
                      alt="contact-img"
                    />
                  </div>
                </div>
                <div className="col-xxl-5 col-lg-5 col-12">
                  <div className="contact-text-holder">
                    <div className="text-heading">
                      <h1 className="title">Say hello</h1>
                      <p>
                        Whether you’re a customer, job seeker, health
                        professional or an investor, you can find the best way
                        to contact us from the options below. We’d love to hear
                        from you.
                      </p>
                    </div>
                    <div className="row justify-content-start">
                      <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-5 col-12">
                        <div className="contact-card">
                        <div className="card-heading">
                          <h1 className="title">Company Details</h1>
                        </div>
                          <ul className="list-padding">
                            <li className="list-dots">
                              <Link to={"/"}>
                                <div className="contact-icon">
                                  <div className="icon">
                                    <FontAwesomeIcon icon="fa-solid fa-phone" />
                                  </div>
                                  <div>
                                    <p>+256 312 341 100</p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li className="list-dots">
                              <Link to={"/"}>
                                <div className="contact-icon">
                                  <div className="icon">
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                  </div>
                                  <div>
                                    <p>info@qcil.co.ug</p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-5 col-12">
                        <div className="contact-card">
                        <div className="card-heading">
                          <h1 className="title">Company Details</h1>
                        </div>
                          <ul className="list-padding">
                            <li className="list-dots">
                              <Link to={"/"}>
                                <div className="contact-icon">
                                  <div className="icon">
                                    <FontAwesomeIcon icon="fa-solid fa-phone" />
                                  </div>
                                  <div>
                                    <p>+256 312 341 100</p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li className="list-dots">
                              <Link to={"/"}>
                                <div className="contact-icon">
                                  <div className="icon">
                                    <FontAwesomeIcon icon="fa-solid fa-envelope" />
                                  </div>
                                  <div>
                                    <p>info@qcil.co.ug</p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                   
                      
                    </div>

                    <div className="social">
                        <div className="social-heading">
                            <h1 className="title">Social media handles</h1>
                        </div>
                        <div className="social-icons">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/blue_facebook.png"
                          }
                          className="social-icon-img"
                          alt="facebook"
                        />
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/blue_linkdin.png"
                          }
                          className="social-icon-img"
                          alt="linkdin"
                        />
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/blue_twitter.png"
                          }
                          className="social-icon-img"
                          alt="twitter"
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    </>
  );
}

export default Contact;
