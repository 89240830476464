import {React, useEffect} from "react";
import "./Mediacontacts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Mediacontacts() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Newsroom</h1>
        </div>
      </section>

      <section className="Mediacontacts">
      <div className="blue-bg"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="contact-text-holder">
                <div className="media-contact-heading">
                  <h1 className="title">Media</h1>
                  <h2>Contacts</h2>
                </div>

                <div className="number-mail-holder">
                  <div className="contact">
                    <div className="icon">
                      <FontAwesomeIcon className="contact-icon" icon="fa-solid fa-phone" />
                    </div>
                    <div>
                      <p>+256 312 341 100</p>
                    </div>
                  </div>
                  <div className="contact">
                    <div className="icon">
                      <FontAwesomeIcon className="contact-icon" icon="fa-solid fa-envelope" />
                    </div>
                    <div>
                      <p>info@qcil.co.ug</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mediacontacts;
