import { useState, useEffect } from "react";
import "../stepform.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

// import { Link, useNavigate, useParams } from "react-router-dom";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import CustomDatePicker from '../CustomDatePicker';

// const navigate = useNavigate();

// const [formData, setFormData] = useState({

// });
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";

function Personalform() {
  const [date, setDate] = useState({ year: "", month: "", day: "" });

  const [monthError, setMonthError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [yearError, setYearError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [item, setItem] = useState({ kindOfStand: "", another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      kindOfStand: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
    <>
      <section className="formm-sec">
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Family / Last Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter First Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Middle Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Maiden Name, if any"
                />
              </Form.Group>

              {/* <Form.Group as={Col} md="6" className='form-group'>
                <Form.Control
                  required
                  type="date"
                  placeholder=""
                />
              </Form.Group> */}

              <Form.Group as={Col} lg="6" md="12" className="form-group">
                <div className="d-flex gender-flex space-between ">
                  <div className="type">
                    <label className="form-label date-text">
                      Date of Birth :{" "}
                    </label>
                  </div>

                  <div className="d-flex">
                    <MonthPicker
                      defaultValue={"MM"}
                      numeric // to get months as numbers
                      endYearGiven // mandatory if end={} is given in YearPicker
                      year={date.year} // mandatory
                      value={date.month} // mandatory
                      onChange={(month) => {
                        // mandatory
                        setDate((prev) => ({ ...prev, month }));
                        setMonthError(false);
                      }}
                      id={"month"}
                      classes={`dropdown select-dropdown ${
                        dayError ? "error" : ""
                      }`}
                      optionClasses={"option"}
                    />
                    <DayPicker
                      defaultValue={"DD"}
                      year={date.year} // mandatory
                      month={date.month} // mandatory
                      endYearGiven // mandatory if end={} is given in YearPicker
                      value={date.day} // mandatory
                      onChange={(day) => {
                        // mandatory
                        setDate((prev) => ({ ...prev, day }));
                      }}
                      id={"day"}
                      classes={`dropdown select-dropdown ${
                        monthError ? "error" : ""
                      }`}
                      optionClasses={"option"}
                    />
                    <YearPicker
                      defaultValue={"YYYY"}
                      start={2000} // default is 1900
                      end={2023} // default is current year
                      reverse // default is ASCENDING
                      value={date.year} // mandatory
                      onChange={(year) => {
                        // mandatory
                        setDate((prev) => ({ ...prev, year }));
                      }}
                      id={"year"}
                      classes={`dropdown select-dropdown ${
                        yearError ? "error" : ""
                      }`}
                      optionClasses={"option"}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Place of Birth"
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Nationality(ies)"
                />
              </Form.Group>

              <Form.Group as={Col} lg="6" md="12" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Present Nationality(ies)"
                />
              </Form.Group>

              <Form.Group as={Col} lg="6" md="12" className="form-group">
                <div className="d-flex gender-flex">
                  <div className="type">
                    <label className="form-label">Sex : </label>
                  </div>

                  {/* <div className='type'>                    
                    <Form.Check  value="design" type="radio" aria-label="radio 1" label="Male"  />
                  </div>

                  <div className='type'>                    
                    <Form.Check  value="design" type="radio" aria-label="radio 2" label="Female" />
                  </div>

                  <div className='type'>                    
                    <Form.Check  value="design" type="radio" aria-label="radio 3" label="Other" />
                  </div> */}

                  <div className="type">
                    <Form.Check
                      value="male"
                      type="radio"
                      aria-label="radio 1"
                      label="Male"
                      onChange={handleChange}
                      checked={kindOfStand === "male"}
                    />
                  </div>

                  <div className="type">
                    <Form.Check
                      value="female"
                      type="radio"
                      aria-label="radio 2"
                      label="Female"
                      onChange={handleChange}
                      checked={kindOfStand === "female"}
                    />
                  </div>

                  <div className="type">
                    <Form.Check
                      value="other"
                      type="radio"
                      aria-label="radio 3"
                      label="Other"
                      onChange={handleChange}
                      checked={kindOfStand === "other"}
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} lg="6" md="12" className="form-group">
                <Row>
                  <Form.Group as={Col} md="3" className="form-group">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Height"
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3" className="form-group">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Weight"
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="6" className="form-group">
                    <Form.Select
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option value="1">Single</option>
                      <option value="2">Married</option>
                      <option value="3">Separated</option>
                      <option value="4">Divorced</option>
                      <option value="5">Widowed</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <textarea
                  rows={1}
                  required
                  type="text"
                  placeholder="Enter Permanent Address"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <textarea
                  rows={1}
                  required
                  type="text"
                  placeholder="Enter Present Address (if different)"
                />
              </Form.Group>

              <Row>
                <Form.Group as={Col} md="6" className="form-group">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Home District"
                  />
                </Form.Group>
              </Row>

              <Form.Group as={Col} md="6" className="form-group">
                <div className="d-flex gender-flex">
                  <div className="type">
                    <label className="form-label">
                      Current Residential Area :{" "}
                    </label>
                  </div>

                  <div className="type">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Villiage"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Division"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Division"
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Telephone No."
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Email Address"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <textarea
                  rows={1}
                  required
                  type="text"
                  placeholder="Enter Any Major Sickness or Accident (if applicable give details)"
                />
              </Form.Group>

              <Form.Group as={Col} lg="6" md="12" className="form-group">
                <div className="d-flex gender-flex">
                  <div className="type">
                    <label className="form-label">Next of Kin : </label>
                  </div>

                  <div className="type">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Name"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} lg="3" md="12" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Contact"
                />
              </Form.Group>

              <Form.Group as={Col} lg="3" md="12" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Relationship"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="form-check-label">
                  Name, Contact & Relationship in Case of Emergency (if
                  different from Kin)
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <Form.Control required type="text" placeholder="Enter Name" />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Contact"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Relationship"
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
}

export default Personalform;
