import { useState, useEffect } from "react";
import '../../stepform.css';

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

const Academicformstepone = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [item, setItem] = useState({ kindOfStand: "", another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      kindOfStand: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
    <>
      <section className="formm-sec bottom-margin pb-5" id="bottom-space-2">
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex">
                  <div className="type">
                    <label className="form-label">
                      Current Residential Area :{" "}
                      <span>
                        (please give exact titles of degrees, certificates
                        attained and year of graduation)
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name of Universities / Institutions"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Country"
                />
              </Form.Group>

              <Form.Group as={Col} md="8" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Degree Academic Distinctions Obtained"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Attended from - Month , Year"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Attended to - Month , Year"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Year of Graduation"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex end">
                  <div className="type">
                    <label className="form-label" id="underline">
                      Add More
                    </label>
                  </div>
                </div>
              </Form.Group>

              
            </Row>
          </Form>
        </div>
      </section>

      <section className="formm-sec" >
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex">
                  <div className="type">
                    <label className="form-label">
                    Schools or other Formal Training/Education{" "}
                      <span>
                      (high school, technical school, apprenticeship etc.)
                      </span>{" "}
                    </label>
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name of Schools or Institute"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Country"
                />
              </Form.Group>

              <Form.Group as={Col} md="8" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Type Education / Training"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Attended from - Month , Year"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Attended to - Month , Year"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Certificate/Diploma Obtained"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex end">
                  <div className="type">
                    <label className="form-label" id="underline">
                      Add More
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Academicformstepone;
