import React from 'react'
import "./Pageup.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Pageup() {
  return (
  <>
     {/* page-up button start */}

     <div className="main-page-up">
        <div className="page-up"  >
            <div className="page-up-circle" >
              <div className="text" onClick={() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }}>
                <div className="up-arrow">
                  <FontAwesomeIcon className="arrow" icon="fa-solid fa-arrow-up" />
                </div>
                <p>Page Up</p>
              </div>
            </div>
          </div>
        </div>
  
          {/* page-up button end */}
  </>
  )
}

export default Pageup;