import { useState, useEffect } from "react";
import '../../stepform.css';

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

function Academicformstepfour() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [item, setItem] = useState({ kindOfStand: "", another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      kindOfStand: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
<>
<section className="formm-sec" id="bottom-space-2">
        <div className="container">
          <Form>
            <Row>

            <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Non-Occupational Activities</label>
                  </div>

                  <div className="">
                    <textarea
                      rows={1}
                      required
                      type="text"
                      placeholder="Enter Non-Occupational Activities"
                    />
                  </div>
                </div>
              </Form.Group>

       
              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex end">
                  <div className="type">
                    <label className="form-label" id="underline">
                      Add More
                    </label>
                  </div>
                </div>
              </Form.Group>

              
            </Row>
          </Form>
        </div>
      </section>
</>
  )
}

export default Academicformstepfour