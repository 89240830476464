import React from 'react';
import './App.css';
import './index.css';
import { useState, useEffect, } from 'react';
import {useLocation} from "react-router-dom"

// import {BrowserRouter as Router , Route , Link} from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer';



import { Route, Routes } from 'react-router-dom';

import Home from './home/Home';
import Vision from './our_company/who_we_are/vision/Vision';
import Values from './our_company/who_we_are/values/Values';
import QCIL from './our_company/our_history/QCIL/QCIL'
import Foundingdirector from './our_company/our_history/Foundingdirector/Foundingdirector'
import Contact from './contact/Contact';
import Privacypolicy from './privacy_policy/Privacypolicy';
import Pageup from './pageup/Pageup';
import Boardmembers from './our_company/our_people/board_members/Boardmembers';
import Leadershipteam from './our_company/our_people/leadership_team/Leadershipteam';
import Life from './our_company/our_people/life_at_qcil/Life';
import Awards from './our_company/our_people/awards_rewards/Awards';
import Sitetour from './our_company/our_people/site_tour/Sitetour';
import Opportunity from './careers/opportunity/Opportunity';
import Hrform from './careers/hr_form/Hrform';
import Trade from './our_company/partnership/trade/Trade';
import Regulatory from './our_company/partnership/regulatory/Regulatory';
import Licence from './our_company/partnership/licence/Licence';
import Wheretofind from './our_company/where_to_find/Wheretofind';
import Founder from './about_us/founder/Founder';
import Productportfolio from './our_product/product_portfolio/Productportfolio';
import Hivaids from './our_product/therapy_areas/anti_infectives/hiv_aids/Hivaids';
import Malaria from './our_product/therapy_areas/anti_infectives/malaria/Malaria';
import EHSimpact from '../src/our_impact/ehs_impact/EHSimpact';
import Access from './our_impact/access/Access';
import Ethics from './our_impact/ethics/Ethics';
import Responsible from './our_impact/responsible/Responsible';
import Environmental from './our_impact/environmental/Environmental';
import Supplier from './our_impact/supplier/Supplier';
import Corporate from './our_impact/corporate/Corporate';
import Financialreports from './investor_relations/financial_reports/Financialreports';
import Annualreports from './investor_relations/annual_reports/Annualreports';
import Announcement from './investor_relations/announcement/Announcement';
import Performance from './investor_relations/performance/Performance';
import Governance from './investor_relations/governance/Governance';
import FandQ from './investor_relations/F&Q/FandQ';
import News from './newsroom/news/News';
import Library from './newsroom/library/Library';
import Newsinner from './newsroom/news/new_inner/NewsInner';
import Mediacontacts from './newsroom/media_contacts/Mediacontacts';
import Antibiotics from './our_product/therapy_areas/anti_infectives/antibiotics/Antibiotics';
import Hepatitis from './our_product/therapy_areas/anti_infectives/hepatitis/Hepatitis';
import Cardiodiabetic from './our_product/therapy_areas/cardio_diabetic/Cardiodiabetic';
import Derma from './our_product/therapy_areas/derma/Derma';
import Gastro from './our_product/therapy_areas/gastro/Gastro';
import Othermedicines from './our_product/therapy_areas/other_medicines/Othermedicines';
import Painmanagement from './our_product/therapy_areas/pain_management/Painmanagement';
import Respiratory from './our_product/therapy_areas/respiratory/Respiratory';
import Accreditations from './our_product/quality/accreditations/Accreditations';
import Stepform from './career_step_form/Stepform';

// import Stepform2 from './career_step_form2/Stepform';
import Stepform2 from './career_step_form2/Stepform2';
import Academicstepform from './career_step_form2/academic_tab/Academicstepform';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  
  return (
    <>  
      <Header />
      <Routes>  
             
        <Route path="/" element={<Home/>} /> 
        <Route path="/who-we-are/vision" element={<Vision/>} /> 
        <Route path="/who-we-are/values" element={<Values/>} />
        <Route path="/our-history/qcil" element={<QCIL/>} />
        <Route path="/our-history/foundingdirector" element={<Foundingdirector/>} />
        <Route path="/contact" element={<Contact/>} /> 
        <Route path="/privacypolicy" element={<Privacypolicy/>} /> 
        <Route path="/our-people/board-members" element={<Boardmembers/>} />
        <Route path="/our-people/leadership-team" element={<Leadershipteam/>} /> 
        <Route path="/our-people/life-at-qcil" element={<Life/>} /> 
        <Route path="/our-people/awards-rewards" element={<Awards/>} /> 
        <Route path="/our-people/Sitetour" element={<Sitetour/>} /> 
        <Route path="/careers/career-opportunity" element={<Opportunity/>} /> 
        <Route path="/careers/hr_form" element={<Hrform/>} /> 
        <Route path="/our_company/trade" element={<Trade/>} /> 
        <Route path="/our_company/regulatory" element={<Regulatory/>} /> 
        <Route path="/our_company/licence" element={<Licence/>} /> 
        <Route path="/our_company/where_to_find" element={<Wheretofind/>} /> 
        <Route path="/about_us/founder" element={<Founder/>} /> 
        <Route path="/our_product/product_portfolio" element={<Productportfolio/>} /> 
        <Route path="/our_product/hiv_aids" element={<Hivaids/>} /> 
        <Route path="/our_product/malaria" element={<Malaria/>} /> 
        <Route path="/our_product/antibiotics" element={<Antibiotics/>} /> 
        <Route path="/our_product/hepatitis" element={<Hepatitis/>} /> 
        <Route path="/our_product/cardiodiabetic" element={<Cardiodiabetic/>} /> 
        <Route path="/our_product/derma" element={<Derma/>} /> 
        <Route path="/our_product/gastro" element={<Gastro/>} /> 
        <Route path="/our_product/othermedicines" element={<Othermedicines/>} /> 
        <Route path="/our_product/painmanagement" element={<Painmanagement/>} /> 
        <Route path="/our_product/respiratory" element={<Respiratory/>} /> 
        <Route path="/our_product/accreditations" element={<Accreditations/>} /> 
        <Route path="/our_impact/ehs_impact" element={<EHSimpact/>} /> 
        <Route path="/our_impact/access" element={<Access/>} /> 
        <Route path="/our_impact/ethics" element={<Ethics/>} /> 
        <Route path="/our_impact/responsible" element={<Responsible/>} /> 
        <Route path="/our_impact/environmental" element={<Environmental/>} /> 
        <Route path="/our_impact/supplier" element={<Supplier/>} /> 
        <Route path="/our_impact/corporate" element={<Corporate/>} /> 
        <Route path="/investor_relations/financialreports" element={<Financialreports/>} /> 
        <Route path="/investor_relations/annualreports" element={<Annualreports/>} /> 
        <Route path="/investor_relations/announcement" element={<Announcement/>} /> 
        <Route path="/investor_relations/performance" element={<Performance/>} /> 
        <Route path="/investor_relations/governance" element={<Governance/>} /> 
        <Route path="/investor_relations/f&q" element={<FandQ/>} /> 
        <Route path="/newsroom/news" element={<News/>} /> 
        <Route path="/newsroom/newsinner" element={<Newsinner/>} /> 
        <Route path="/newsroom/library" element={<Library/>} /> 
        <Route path="/newsroom/mediacontacts" element={<Mediacontacts/>} /> 

        {/* <Route path="/career-form" element={<Stepform/>} />  */}
        Academicstepform
        <Route path="/career-form2" element={<Stepform2/>} />
        <Route path="/Academicstepform" element={<Academicstepform/>} />

      </Routes>
      <Pageup/>
      <Footer />
    </>
  );
}

export default App;
