import { React, useRef, useEffect } from "react";
import "./QCIL.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function QCIL() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our History</h1>
        </div>
      </section>

      <section className="main">
        <div className="container-fluid p-0">
          <section className="QCIL">
            <div className="history-content">
              <div className="container">
                <div className="sub-heading-title">
                  <h1 className="title">
                    The data we put are dummy will replace after <br /> received
                    original data
                  </h1>
                </div>

                <div className="timeline-data">
                  <div className="timeline-bg">
                    <img
                      className="timeline-bg-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/image/our-company/our-history/timeline/timeline.png"
                      }
                      alt="timeline-bg-img"
                    />
                  </div>
                  <div className="year-data">
                  <div className="row">
                    <div className=" col-lg-3 col-md-4">
                      <div className="year-data" id="data-1">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-4 col-lg-3 col-md-4">
                      <div className="year-data" id="data-2">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="year-data" id="data-3">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-lg-4 col-md-5 mx-auto ">
                      <div className="year-data" id="data-4">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="year-data" id="data-5">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="year-data" id="data-6">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-7 mx-auto">
                      <div className="year-data" id="data-7">
                        <div className="year-list-box">
                          <div className="box">
                            <FontAwesomeIcon
                              className="red-box"
                              icon="fa-solid fa-square"
                            />
                          </div>
                          <div className="purple-box"></div>
                        </div>
                        <div className="data-content">
                          <h1 className="title">2004</h1>
                          <p>
                            In 2004, Quality Chemicals <br /> Limited (QCL)
                            convinced Indian <br /> drug maker QCIL to go into
                            a <br /> joint venture with QCL and the <br />{" "}
                            government of Uganda to <br /> establish a
                            pharmaceutical <br /> plant in Uganda.
                          </p>
                          <div className="dashred-line"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default QCIL;
