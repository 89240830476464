import {React, useEffect} from "react";
import "../../product_style/Product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Hivaids() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Therapy Areas</h1>
        </div>
      </section>

      <section className="Hivaids">
        <div className="main-holder">
          <div className="heading">
            <p className="title">Anti-infectives</p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="main-content-holder">
                  <div className="row justify-content-center">
                    <div className="col-md-5">
                      <div className="sub-heading-holder">
                        <div className="sub-heading">
                          <p>HIV /AIDS</p>
                        </div>
                        <h1 className="title" id="left">
                          It is impossible to <br /> deny anyone
                        </h1>
                        <vr class="border-dashed"></vr>
                      </div>
                    </div>
                    <div className="col-md-7 ps-0">
                      <div className="text-content-holder">
                        <p>
                          At QCIL, we stand by our motto that everyone should
                          have access to lifesaving medication. In the crusade
                          against HIV, QCIL has manufactured medication that has
                          revolutionized HIV treatment, changing it from
                          something that was previously considered a death
                          sentence, to something that can be managed like any
                          other chronic condition. <br /> <br /> In 2001, QCIL
                          introduced the world’s first-ever recommended 3-in-1
                          fixed-dose combination (Stavudine + Lamivudine +
                          Nevirapine) to fight AIDS. It was made available at
                          less than $1 per day compared to over $12,000 per
                          patient per year prevailing in Uganda and most
                          countries around the world.
                        </p>
                        <div className="main-img-div">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="img-holder">
                                <img
                                  className="infectives-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/our_product/anti-infectives/hiv_img.png"
                                  }
                                  alt="infectives-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hivaids;
