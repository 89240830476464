import {React, useEffect} from "react";
import "./Subscribe.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Subscribe() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="main">
      <section className="Subscribe">
        <div className="subscribe-content">
          <div className="container">
            <div className="subscribe-box">
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="box">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="inner-box">
                          <div className="text-holder">
                            <h5 className="title">Subscribe Newsletter</h5>
                            <p>
                              Sign up to receive occasional communication,<br />
                              investor updates and company news.
                            </p>
                            <div className="subscribe-box">
                              <div className="text-holder">
                                <Form.Control
                                  type="text"
                                  placeholder="Your email address"
                                />
                              </div>

                              <div className="subscribe-btn-holder">
                                <Button className="subscribe-btn">Subscribe</Button>{" "}
                              </div>
                            </div>
                            <p>We promise not to spam you!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscribe;
