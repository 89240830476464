import { useState, useEffect } from "react";
import "../stepform.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

function Tabform () {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [item, setItem] = useState({ kindOfStand: "", another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      kindOfStand: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
    <>
      <section className="formm-sec pb-5" id="bottom-space">
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Have You Ever Been Arrested, Indicted, Summoned into Court as a Defendant in a Criminal Proceeding, Convicted or Fined, or Involved in any Litigation? (if applicable give details)</label>
                  </div>

                  <div className="">
                    <textarea
                      rows={1}
                      required
                      type="text"
                      placeholder="Enter Details"
                    />
                  </div>
                </div>
              </Form.Group>

            </Row>
          </Form>
        </div>
      </section>

      <section className="formm-sec pb-5">
        <div className="container">
          <Form>
            <Row>
            <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Professional References (List three persons, not related to you, who are familiar with your character and qualifications)</label>
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Telephone Contact"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Position / Role in QICL"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Business / Occupation"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Telephone Contact"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Position / Role in QICL"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Business / Occupation"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Telephone Contact"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Position / Role in QICL"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Business / Occupation"
                />
              </Form.Group>


            </Row>
          </Form>
        </div>
      </section>

      <section className="formm-sec">
        <div className="container">
          <Form>
            <Row>

            <Form.Group as={Col} md="12" className='form-group'>
                <div className='d-flex space-between gender-flex'>
                  <div className='type'>
                    <label className='form-label'>Do You Have Any Objections to Our Making Background Inquiries on Your Person? </label>
                  </div>


                  <div className='type'>
                    <Form.Check value="Yes" type="radio" aria-label="radio 1" label="Yes"
                      onChange={handleChange}
                      checked={kindOfStand === "Yes"}
                    />
                  </div>

                  <div className='type'>
                    <Form.Check
                      value="No"
                      type="radio"
                      aria-label="radio 2"
                      label="No"
                      onChange={handleChange}
                      checked={kindOfStand === "No"}
                    />
                  </div>

                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Declaration by the Applicant:</label>
                  </div>

                  <div className="">
                    <textarea
                      rows={5}
                      required
                      type="text"
                      placeholder="I hereby declare and certify that the statements made by me in answer to the foregoing questions are true, complete, and correct to the best of my knowledge & belief. If at any time, it is found that I have omitted or concealed any material information, or given false statements, my services shall be liable to termination without notice or salary in lieu thereof. Should there be a change in any of the information given above, I undertake to inform the Company, in writing immediately. I will abide by all the rules and regulations of the Company, existing and brough into force from time to time."
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className='form-group'>
                  <div className='d-flex gender-flex'>
                   <div className='type'>
                      <label className='form-label'>Date: </label>
                   </div>
                   
                   <div className='type' id="full-width">
                      <Form.Control 
                      required
                      type="text"
                      placeholder="" 
                    />
                   </div>
                  </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className='form-group'>
                  <div className='d-flex gender-flex'>
                   <div className='type'>
                      <label className='form-label'>Signature: </label>
                   </div>
                   
                   <div className='type' id="full-width">
                      <Form.Control 
                      required
                      type="text"
                      placeholder="" 
                    />
                   </div>
                  </div>
              </Form.Group>

              <div className="note">
                <p className="title"> <span>N.B.</span> You will be requested to supply documentary evidence which supports the statements you have made in this application. Do not, however, send any documentary evidence until you have been asked to do so by the Company, and, in any event, do not submit the original texts of reference or testimonials unless they have been obtained for the sole use of the Company.</p>
              </div>

            </Row>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Tabform;
