import { React, useEffect } from "react";
import "./Hrform.css";
import { Link } from "react-router-dom";

function Hrform() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Careers</h1>
        </div>
      </section>

      <section className="join-us-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="back-bg">
                <div className="row">
                  <div className="col-xl-9 col-md-8">
                    <div className="first-sec border-right">
                      <h6 className="join-us-title">Join Us</h6>
                      <p className="sub-text">
                        Kindly fill the form to join the QCIL
                      </p>

                      <div className="d-flex">
                        <div className="inner-div">
                          <Link className="link-text title" to="/">
                            View Form
                          </Link>
                        </div>
                        <div className="inner-div">
                          <Link
                            className="link-text title"
                            to={
                              "https://profcymadev.in/Recruitment-Personal Data Form-Oct 23.docx"
                            }
                          >
                            Download Form{" "}
                            <img
                              className="download-icon"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/icons/arrow/download.png"
                              }
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-4">
                    <div className="first-sec">
                      <p className="sub-text">Upload the form that you fill</p>

                      <button className="btn">
                        <img
                          className="upload-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/arrow/upload.png"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hrform;
