import {React, useEffect} from "react";
import Button from "react-bootstrap/Button";
import "./Mission.css";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

// Install the required Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function Mission() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <section className="main">
      <div className="container-fluid p-0">
        <section className="Mission">
          <div className="Mission-content">
            <div className="container">
              <div className="text-holder">
                <h4 className="title">
                  The QCIL's mission is to ensure everyone has <br />
                  access to lifesaving medications
                </h4>
              </div>

              {/* swiper section start */}
              <div className="mission-slider">
                <Swiper
                  className="Mission-swiper"
                  centeredSlides="true"
                  navigation={{
                    nextEl: ".swiper-button-next", // CSS class for the next button
                    prevEl: ".swiper-button-prev", // CSS class for the previous button
                  }}
                  pagination={{ clickable: true }}
                  //   autoplay={{ delay: 3000 }}
                  loop={true}
                  slidesPerView={3}
                   // Display one slide at a time
                >
                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_1.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>Hepatitis</h5>
                            <p>
                              We have played a significant role in the
                              management of Hepatitis B & C with a wide range of
                              drugs.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_2.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>HIV / AIDS</h5>
                            <p>
                              For over two decades, we stood by our motto, “None
                              shall be denied”.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_3.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>Antimalarials</h5>
                            <p>
                              High quality and affordable treatments for people
                              suffering from severe malaria.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_1.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>Hepatitis</h5>
                            <p>
                              We have played a significant role in the
                              management of Hepatitis B & C with a wide range of
                              drugs.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_2.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>HIV / AIDS</h5>
                            <p>
                              For over two decades, we stood by our motto, “None
                              shall be denied”.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Mission/img_3.png"
                        }
                        className="mission-slider-img"
                        alt="..."
                      />
                      <div className="overlay">
                        <div className="text-holder">
                          <div className="text">
                            <h5>Antimalarials</h5>
                            <p>
                              High quality and affordable treatments for people
                              suffering from severe malaria.
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Button className="read-more">
                              <FontAwesomeIcon
                                className="read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
              {/* swiper section end */}
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Mission;
