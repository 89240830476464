import {React,useEffect} from "react";
import "./Annualreports.css";
import Button from "react-bootstrap/Button";

function Annualreports() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="Annualreports">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="annual-reports-content">
                <div className="row justify-content-center">
                  <div className="col-lg-3">
                    <div className="report-heading">
                      <h1 className="title">Annual</h1>
                      <h2>Reports</h2>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="years-and-data-holder">
                      <div className="years">
                      <div className="year-holder">
                          <h1 className="tittle">2023</h1>
                        </div>
                        <div className="year-holder">
                          <h1 className="tittle">2022</h1>
                        </div>

                        <div className="year-holder">
                          <h1 className="tittle">2021</h1>
                        </div>

                        <div className="year-holder">
                          <h1 className="tittle">2020</h1>
                        </div>

                        <div className="year-holder">
                          <h1 className="tittle">2019</h1>
                        </div>

                        <div className="year-holder">
                          <h1 className="tittle">2018</h1>
                        </div>
                      </div>

                      <div className="data-table">
                        <div className="data-box">
                          <div className="box">
                            <div className="dashed-box"></div>
                          </div>
                          <div className="data-title">
                          <p className="tittle">Annual Report</p>
                          </div>
                          <div className="butn">
                            <div className="purple-read-more"  >
                              <Button className="purple-read-more-btn" id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/CIPLAQCIL-ANNUAL-REPORT-2023.pdf">
                              Open
                              </Button>{" "}
                            </div>
                          </div>
                        </div>

                        <div className="data-box">
                          <div className="box">
                            <div className="dashed-box"></div>
                          </div>
                          <div className="data-title">
                          <p className="tittle">Annual Report</p>
                          </div>
                          <div className="butn">
                            <div className="purple-read-more">
                              <Button className="purple-read-more-btn" id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2022/08/CIPLA Annual Report final opt.pdf">
                              Open
                              </Button>{" "}
                            </div>
                          </div>
                        </div>

                        <div className="data-box">
                          <div className="box">
                            <div className="dashed-box"></div>
                          </div>
                          <div className="data-title">
                          <p className="tittle">Annual Report</p>
                          </div>
                          <div className="butn">
                            <div className="purple-read-more" > 
                              <Button className="purple-read-more-btn"  id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2021/09/693460199-Cipla-QCIL-annual-report-2021-optimised-2.pdf">
                              Open
                              </Button>{" "}
                            </div>
                          </div>
                        </div>

                        <div className="data-box">
                          <div className="box">
                            <div className="dashed-box"></div>
                          </div>
                          <div className="data-title">
                          <p className="tittle">Annual Report</p>
                          </div>
                          <div className="butn">
                            <div className="purple-read-more"  >
                              <Button className="purple-read-more-btn" id="btn-size" target="_blank" href="https://station.cipla.co.za/app/#/s/c7nzzh_IOF">
                              Open
                              </Button>{" "}
                            </div>
                          </div>
                        </div>

                        <div className="data-box">
                          <div className="box">
                            <div className="dashed-box"></div>
                          </div>
                          <div className="data-title">
                          <p className="tittle">Annual Report</p>
                          </div>
                          <div className="butn">
                            <div className="purple-read-more"  >
                              <Button className="purple-read-more-btn" id="btn-size" target="_blank" href="https://station.cipla.co.za/app/#/s/kcJxsI_">
                              Open
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Annualreports;
