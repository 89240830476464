import { React, useEffect } from "react";
import "./Media.css";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
library.add(fas);

// Install the required Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function Media() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <section className="main">
      <div className="container-fluid p-0">
        <section className="media">
          <div className="media-content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-5 col-lg-5 col-12">
                  <div className="headeing ">
                    <div className="swiper-heading">
                      <h5 className="title">Latest</h5>
                      <h1 className="title">Media Releases</h1>
                    </div>
                    <div className="main-img">
                      <img  className="swiper-main-img"  src={ process.env.PUBLIC_URL + "assets/image/Home/Media/main_img_1.png"} alt="..."/>
                      <div className="overlay">
                        <div className=" date-holder">
                          <p>19 Oct 2023</p>
                        </div>
                        <div className="main-text-holder">
                          <div className="text-holder">
                            <h1 className="title"> Cautionary <br /> Announcement</h1>
                            <p> On 14 March 2023, the Board of Directors <br /> of QCIL Quality Chemical Industries Limited <br /> (the “Company”) was advised by Africa <br /> Capitalworks SSA 3 (the “Offeror”)</p>
                          </div>
                          <div className="btn-holder">
                            <Link to={"/newsroom/newsinner"}>
                              <Button className="read-more">
                                <FontAwesomeIcon className="read-more-btn" icon="fa-solid fa-arrow-right" />
                                {/* <img
                                  className="right"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/icons/arrow/right.png"
                                  }
                                  alt=""
                                /> */}
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-5 col-lg-5 col-12">
                  <div className="swiper-section">

                    <Swiper
                      className="media-swiper"
                      navigation={{
                        nextE1: ".swiper-button-next", // CSS class for the next button
                        prevE1: ".swiper-button-prev", // CSS class for the previous button
                      }}
                      pagination={{ clickable: true }}
                      loop={false}
                      slidesPerView={1}>
                      
                      <SwiperSlide>
                        <div className="slider-content">
                          <div className="img-content">
                            <div className="media-card-img-content">
                              <div className="bg-img">
                                <img
                                  className="card-bg-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/image/Home/Media/medie_bg.png"
                                  }
                                  alt="..."
                                />
                              </div>
                              <div className="cards">
                                <div className="row ">
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center ">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_1.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                Hope In Every Drop
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                To address the growing blood
                                                deficit in Uganda, Quality
                                                Chemical
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_2.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                QCIL Registers Growth...
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                {" "}
                                                The last two years have
                                                presented serious business,
                                                health, and...
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-content">
                          <div className="img-content">
                            <div className="media-card-img-content">
                              <div className="bg-img">
                                <img
                                  className="card-bg-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/image/Home/Media/medie_bg.png"
                                  }
                                  alt="..."
                                />
                              </div>
                              <div className="cards">
                                <div className="row ">
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center ">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_1.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                Hope In Every Drop
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                To address the growing blood
                                                deficit in Uganda, Quality
                                                Chemical
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_2.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                QCIL Registers Growth...
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                {" "}
                                                The last two years have
                                                presented serious business,
                                                health, and...
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-content">
                          <div className="img-content">
                            <div className="media-card-img-content">
                              <div className="bg-img">
                                <img
                                  className="card-bg-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/image/Home/Media/medie_bg.png"
                                  }
                                  alt="..."
                                />
                              </div>
                              <div className="cards">
                                <div className="row ">
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center ">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_1.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                Hope In Every Drop
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                To address the growing blood
                                                deficit in Uganda, Quality
                                                Chemical
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                  <div className=" col-12">
                                    {" "}
                                    <Card className="mb-2">
                                      <div className=" row justify-content-center">
                                        <div className=" col-xxl-4 col-md-3 col-lg-4">
                                          <div className="media-card-img">
                                            <Card.Img
                                              variant="top"
                                              src="assets/image/Home/Media/media_img_2.png"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-8">
                                          <Card.Body>
                                            <Card.Title>
                                              <h5 className="title">
                                                QCIL Registers Growth...
                                              </h5>
                                            </Card.Title>
                                            <Card.Text>
                                              <p>
                                                {" "}
                                                The last two years have
                                                presented serious business,
                                                health, and...
                                              </p>
                                            </Card.Text>
                                            <div className="btn-holder">
                                              <Button className="trans-read-more">
                                                <FontAwesomeIcon
                                                  className="trans-read-more-btn"
                                                  icon="fa-solid fa-arrow-right"
                                                />
                                                {/* <img
                                                  className="right"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "assets/icons/arrow/right.png"
                                                  }
                                                  alt=""
                                                /> */}
                                              </Button>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Media;
