import {React, useEffect} from 'react'
import "./Regulatory.css"

function Regulatory() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
   <>
     <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Partnership</h1>
        </div>
      </section>

      <section className="Trade">
        <div className="container">
          <div className="section-common-title">
            <h6 className="sec-title">Regulatory Partners</h6>
          </div>
        </div>

        <div className="google-map">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div style={{ width: "100%" }}>
                  <iframe
                    width="100%"
                    height="600"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=africa+(My%20Business%20Name)&amp;t=&amp;z=3&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    title="Embedded Google Map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   </>
  )
}

export default Regulatory;