import {React, useEffect} from "react";
import "./Sitetour.css";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay, Pagination]);

const Sitetour = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our People</h1>
        </div>
      </section>

      <section className="Sitetour-section">
        <div className="container">

          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-5 col-md-8 col-12 mx-auto ">
              <Swiper
                className="site-tour-swiper"
                navigation={{
                  nextE1: ".swiper-button-next", // CSS class for the next button
                  prevE1: ".swiper-button-prev", // CSS class for the previous button
                }}
                pagination={{ clickable: true }} // Include pagination options
                //   autoplay={{ delay: 3000 }}
                loop={true}
                slidesPerView={1}
                // breakpoints={{
                //     '0px': {
                //         slidesPerView: 1,
                //         spaceBetween: 10,
                //     },
                //     '768': {
                //         slidesPerView: 2,
                //         spaceBetween: 10,
                //     },
                //     '1200': {
                //         slidesPerView: 3,
                //         spaceBetween: 10,
                //     },
                //     '1400': {
                //         slidesPerView: 4,
                //         spaceBetween: 30,
                //     },
                // }}
              >
               
               <SwiperSlide>
                  <div className="slider-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/main_img_site_tour.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row me-0 ms-0">
                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/main_img_site_tour.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row me-0 ms-0">
                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/main_img_site_tour.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row me-0 ms-0">
                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>

                      <div className="col-md-6 p-0">
                        <div className="img-holder">
                          <img
                            className="site-tour-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-people/site_tour/Slider_img.png"
                            }
                            alt="site-tour"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>

            <div className="col-xl-7 col-lg-7 col-md-12 col-12">
              <div className="text-holder">
                <h1 className="title">Site Tour</h1>
                <p>
                  QCIL has been focused on aggressive market expansion in the
                  past few years, and as a result, the company has been approved
                  by national regulatory bodies across numerous African
                  countries. It is also listed for approvals in many other
                  markets, including South Africa – the single biggest market
                  for antiretrovirals in the world. <br /> <br /> The facility
                  is approved for supply in 31 SSA countries and currently
                  exports to 13 countries in Africa and two in South East Asia.
                  QCIL’s manufacturing facility has also been expanded to
                  increase production capacity from 80 million to 130 million
                  tablets monthly to meet the demand. <br /> <br /> QCIL Focus
                  on Manafacturing Excellence QCIL commissioned a $10 million
                  quality control laboratory – with state-of-the-art equipment
                  such as extractors for drawing dangerous solvents off work
                  benches, detectors that check air pressure and alert in case
                  of leakages, conference rooms and training facilities – which
                  was completed in December 2019. This facility is twice as big
                  as the previous laboratory and accommodates more staff and
                  ensures that the company is compliant with all safety and good
                  laboratory practice requirements. The new facility has also
                  helped to increase productivity and improve production
                  timelines. <br /> <br /> QCIL also constructed a new $5 million warehouse
                  with 3000 sqm space to increase in-house storage capacity and
                  discontinue the use of third-party warehouses. The warehouse
                  has been operational since January 2019 and has the capacity
                  for 5000 pallets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitetour;
