import { React, useEffect } from "react";
import "./Licence.css";

function Licence() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Partnership</h1>
        </div>
      </section>

      <section className="Licence">
        <div className="licence-bg">
          <img
            className="Licence-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/where_to_find_us/licence/licence_bg.png"
            }
            alt="Licence-bg-img"
          />
        </div>
        <div className="licence-content">
          {/* <div className="container"> */}
          <div className="section-title">
            <h1 className="title">Licence</h1>
          </div>
          <div className="content">
            <div className="row justify-content-center  me-0 ms-0">
              <div className="col-md-10">
                <div className="licence-imgs">
                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <div className="img-holder">
                        <img
                          className="licence-image"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our-company/where_to_find_us/licence/Licence_1.png"
                          }
                          alt="licence-image"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="img-holder">
                        <img
                          className="licence-image"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our-company/where_to_find_us/licence/Licence_1.png"
                          }
                          alt="licence-image"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="img-holder">
                        <img
                          className="licence-image"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our-company/where_to_find_us/licence/Licence_1.png"
                          }
                          alt="licence-image"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="img-holder">
                        <img
                          className="licence-image"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our-company/where_to_find_us/licence/Licence_1.png"
                          }
                          alt="licence-image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
      </section>
    </>
  );
}

export default Licence;
