import { useState, useEffect } from "react";
import "./Productportfolio.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Productportfolio() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Product Portfolio</h1>
        </div>
      </section>

      <section className="Productportfolio">
        <div className="container">
          <div className="main-div">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12">
                <div className="main-product-portfolio-content-holder">
                  <div className="row justify-content-center">

                  
                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div" id="protfolio-card">
                          <div className="profile-img-div" id="curve">
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our_product/product_portfolio/dummy_product.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                            Products Images will be received by business
                          </h6>
                          {/* <p className="member-position" >
                            Board Chairman / Executive Director
                          </p> */}

                          <div
                            className="btn-holder"
                            id="center-btn"
                            onClick={handleShow}
                          >
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* offcanvas section start */}
        <div className="top-offcanvas">
          <div className="container">
            <Offcanvas
              className="impact-offcanvas"
              show={show}
              placement="top" // Set the placement to "top" for top position
              onHide={handleClose}
            >
              <div className="bg-img-holder">
                <img
                  className="offcanvas-bg-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/image/our-impact/offcanvas_bg/bg_img.png"
                  }
                  alt="offcanvas-bg-img"
                />
              </div>
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="impact-modal">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-lg-5 col-md-6">
                        <div className="img-div">
                          <div className="slider-content">
                            <div className="row">
                              <div className=" col-md-12 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/our_product/product_portfolio/offcanvas_product.png"
                                    }
                                    alt="offcanvas"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <div className="text-holder">
                          {/* <p>Board Chairman / Executive Director</p> */}
                          <h1 className="title">Products Images will be received <br /> by business</h1>
                          <p>
                          Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. <br /> <br />
                          Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. <br /> <br />
                          Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
        {/* offcanvas section end */}
      </section>
    </>
  );
}

export default Productportfolio;
