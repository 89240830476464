import {React, useEffect} from "react";
import Banner from './banner/Banner';
import Media from './media/Media';
import Worth from './worth/Worth';
import Mission from './mission/Mission';
import Subscribe from './subscribe/Subscribe'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <Banner/>  
      <Media/>   
      <Worth/>
      <Mission/>
      <Subscribe/>
    </>
  );
}

export default Home;
