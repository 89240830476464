import { React, useEffect } from "react";
import "./Privacypolicy.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Privacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const cars = ["Ford", "BMW", "Audi"];
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Policies</h1>
        </div>
      </section>

      <section className="Privacypolicy">
        <div className="container-fluid">
          <div className="main-content-holder">
            <Tabs  defaultActiveKey="Privacy Policy"  transition={false} id="noanim-tab-example"className="mb-5 justify-content-center">
              <Tab eventKey="Privacy Policy" title="Privacy Policy">
                <div className="row justify-content-center">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
                    <div className="text-holder">
                      <p>
                        Quality Chemical Industries Limited is committed to
                        protecting the privacy of any information about you that
                        you provide to us. This policy explains how we will
                        handle your information. Quality Chemical Industries
                        Limited (hereinafter referred to as “QCIL”) urges you to
                        carefully read and review this Privacy Policy Statement
                        before accessing or using the QCIL website (hereinafter
                        referred to as “Website”). If you proceed to access or
                        use this website, you are agreeing to abide by this
                        Privacy Policy Statement, without any exceptions. You
                        may not access or use this Website if you do not agree
                        to this Privacy Policy Statement..
                      </p>
                    </div>

                    {/*  Collection of Personal Information */}
                    <div className="text-holder">
                      <h1 className="title">
                        Collection of Personal Information
                      </h1>
                      <p>
                        The Website is not designed to collect and/or receive
                        any personal information of you by itself. QCIL is not
                        able to identify you personally unless you access
                        Website and/or provide any personal information.
                        <br />
                        <br />
                        Active collection of information: QCIL collects personal
                        information that you enter into data fields on the QCIL
                        Web site. For example, you may submit or share your
                        name, postal address, e-mail address, investor details,
                        profession, photograph, medical records and/ or other
                        personal information in order to receive information
                        about various subjects and services wherever applicable.
                        To protect your privacy, you should not provide QCIL
                        with any information that is not specifically requested.
                        When you register using your other accounts like on
                        Facebook, Twitter, Gmail etc. QCIL may retrieve
                        information required for your registration from such
                        account to continue to interact with you and to continue
                        providing services, wherever applicable.
                        <br />
                        <br />
                        Passive Collection of information: QCIL Website may
                        collect information about your visits to QCIL Web site
                        without you actively submitting such information. This
                        information may be collected using various technologies,
                        such as cookies, Internet tags, and web beacons. The
                        Website may capture some of this information, such as
                        the URL of the website you just visited, Internet
                        Protocol (IP) addresses, GPS location data, mobile phone
                        service provider, details of operating system, the
                        browser version of your computer, etc. Passive
                        information collection technologies can make your use of
                        the Website easier by allowing QCIL to provide better
                        service, customize sites based on consumer preferences,
                        compile statistics, analyse trends, and otherwise
                        administer and improve the Website. Such information
                        collected by these technologies cannot be used to
                        identity you without additional identifiable
                        information.
                      </p>
                    </div>

                    {/*  Intended Use of Personal Information */}
                    <div className="text-holder">
                      <h1 className="title">
                        Intended Use of Personal Information
                      </h1>
                      <p>
                        QCIL will use the personal information you provide
                        through the Website to respond to your queries, have
                        efficient communication and to provide you with
                        efficient service. After you have entered personal
                        information into a form or data field on the Website,
                        QCIL may use certain identifying technologies to allow
                        that website to "remember" your personal preferences,
                        such as sections of the Website that you visit
                        frequently and, if you choose, your login credentials.
                        <br />
                        <br /> QCIL shall collect, store and use your
                        information in compliance with all applicable laws. You
                        may always limit the amount and type of personal
                        information that QCIL receives about you by choosing not
                        to enter any personal information into forms or data
                        fields on the Website. Some of our online services can
                        only be provided to you if you provide us with
                        appropriate personal information. Other parts of the
                        Website may ask whether you wish to opt out or opt into
                        our contact lists for offers, promotions and additional
                        services that may be of interest to you. If opted to do
                        so, we may use this information for marketing and
                        promotional purpose. For example, in accordance with
                        applicable law and with your consent, we may use your
                        email address to send you news and newsletters, special
                        offers, and promotions, and to contact you about
                        products or information we think may interest you. We
                        may also reach out to you with response to any queries
                        posted on our website.
                      </p>
                    </div>

                    {/* Spamming */}
                    <div className="text-holder">
                      <h1 className="title">Spamming</h1>
                      <p>
                        QCIL does not support “spamming”, which is defined as
                        sending unsolicited emails, usually of a commercial
                        nature, in large numbers and repeatedly to individuals
                        with whom the sender has had no previous contact or who
                        have declined to receive such communications. In
                        accordance to your preferences or interests expressed on
                        the Website, QCIL may send periodic emails to you on the
                        email id provided by you on the Website during
                        registration. You may choose to opt out of receiving
                        such emails through a link included in the emails you
                        receive from us.{" "}
                      </p>
                    </div>

                    {/* Non-Disclosure of Information */}
                    <div className="text-holder">
                      <h1 className="title">Non-Disclosure of Information</h1>
                      <p>
                        Personal information on the Website may be accessed by
                        certain companies with which QCIL may conduct joint
                        programs, and by individuals and entities with whom QCIL
                        contracts to carry out business activities for QCIL.
                        <br /> <br /> QCIL does not sell or rent your personal
                        Information with anyone else. <br /> <br /> QCIL may
                        share the personal information with a third-party, if it
                        is required for further processing or connection to its
                        business. During this time the information shared will
                        be in accordance with the confidentiality agreement with
                        the third party, the applicable laws, for the intended
                        purpose for which the Information was originally
                        collected and shall ensure all such third parties comply
                        with QCIL’s Data Protection and Privacy Policy. <br />{" "}
                        <br /> We may release your personal information when we
                        believe release is required to comply with applicable
                        law. We may release personal information if, in our
                        judgment after review, the release is compelled by law
                        or regulation.
                      </p>
                    </div>

                    {/*  Protection of personal information */}
                    <div className="text-holder">
                      <h1 className="title">
                        Protection of personal information
                      </h1>
                      <p>
                        QCIL will maintain adequate technical and organizational
                        security measures to protect your personally
                        identifiable information. <br /> <br /> As a policy,
                        QCIL secures each web page that collects personal
                        information; however, the confidentiality of personal
                        information transmitted over the Internet will not be
                        guaranteed. We urge you to exercise caution when
                        transmitting personal information over the Internet.{" "}
                        <br /> <br /> QCIL is not obliged to store your
                        Information for a period which is beyond the intended
                        purpose for which such Information was collected or
                        submitted or to stay in compliance to applicable laws
                        and QCIL Policies and Procedures.
                      </p>
                    </div>

                    {/* Links to other websites */}
                    <div className="text-holder">
                      <h1 className="title">Links to other websites</h1>
                      <p>
                        This Privacy Policy applies only to QCIL’s website. QCIL
                        may provide links to other websites, which we believe,
                        may be of interest to you. QCIL is not responsible for
                        the content on such website, your access to such website
                        links, security of personal information that you
                        provide, or any information collected by that website.
                        The risk of accessing such websites is solely yours.
                      </p>
                    </div>

                    {/* Cookies */}
                    <div className="text-holder">
                      <h1 className="title">Cookies</h1>
                      <p>
                        Cookies are alphanumeric identifiers with small amount
                        of data that is commonly used as an anonymous unique
                        identifier. These are sent to your browser from the
                        website that you visit and are stored on your computer’s
                        hard drive. Please note, a cookie in no way gives us
                        access to your computer/device and cookies cannot access
                        any other information on your computer/device. <br />{" "}
                        <br /> Our Website uses these cookies to collect
                        information and to improve our Service by making your
                        interaction with us faster. They may be used for the
                        purposes of managing your preferences, maintaining and
                        optimizing security, marketing, communication,
                        analytics, and research. <br /> <br />
                        <b>
                          We majorly use below types of cookies:
                          <br />
                        </b>{" "}
                        <br />{" "}
                        <b>
                          Essential Cookies or Strictly Necessary Cookies –
                        </b>{" "}
                        These cookies are essential to the functioning of our
                        Website and for you to move around the Website. Without
                        these cookies, certain features cannot function. No
                        information about your browsing habits is gathered by
                        these cookies. <br /> <br />{" "}
                        <b> Functional Cookies –</b> These cookies remember how
                        you prefer to use our Website and improve the way our
                        Website works. These remain on your computer/device for
                        a pre-defined period. <br />
                        <br /> <b>Performance Cookies – </b>Some cookies help us
                        with the performance and design of our Website. For
                        example, these cookies show us statistics, which are the
                        most frequently visited pages on the Website, help us
                        record any difficulties you have with the Website, and
                        whether our publicity is effective or not. <br /> <br />{" "}
                        <b>Targeting or Tracking Cookies –</b> On certain pages
                        of our Website, we use cookies to help us understand
                        your interests as you browse the Website, so we can
                        tailor and deliver to you a more personalized service in
                        the future. This assists us in delivering relevant,
                        interest-based information to you. <br /> <br /> At any
                        time, you also have the option to change /control your
                        cookies through your browser settings. However, if you
                        do turn off cookies in your browser, you will not be
                        able to fully experience some of the features our
                        Website.
                      </p>
                    </div>

                    {/*  Use of our websites and mobile application by children */}
                    <div className="text-holder">
                      <h1 className="title">
                        Use of our websites and mobile application by children
                      </h1>
                      <p>
                        QCIL does not knowingly collect or use any personal
                        information from children (we define “children” as those
                        who are younger than 18 years of age) on this Website.
                        We do not knowingly allow children to communicate with
                        us or use any of our online services. If you are a
                        parent or guardian and become aware that your child has
                        provided us with information, please contact us and we
                        will work with you to address this issue.
                      </p>
                    </div>

                    {/*   Right to your personal information on our Website */}
                    <div className="text-holder">
                      <h1 className="title">
                        Right to your personal information on our Website
                      </h1>
                      <p>
                        {" "}
                        <b>Right to access:</b> You have the right to ask for a
                        copy of your personal data and to verify how we are
                        processing it. <br /> <br />
                        <b>Right to rectification:</b> If you believe we have
                        inaccurate or incomplete information about you, you have
                        the right to ask us to correct or update it. <br />{" "}
                        <br />
                        <b>Right to be forgotten:</b> In certain circumstances,
                        you have the right to ask us to remove or erase your
                        personal data from our records. <br /> <br />
                        <b>Right to object:</b> You have the right to object to
                        processing of your personal data. You also have the
                        right not to be subjected to any automated decision
                        making or profiling. <br /> <br />
                        <b>Right to restriction of processing:</b> You have the
                        right to ask us to restrict processing of your personal
                        data in cases where the data is inaccurate, or the
                        processing of the data is unlawful. This does not
                        restrict QCIL from processing of your personal data for
                        legal and regulatory requirements. <br /> <br />
                        <b>Right to withdraw consent:</b> If we process your
                        personal data based on your consent, you can withdraw
                        your consent at any point of time. <br /> <br />
                        <b>Right to portability: </b> You have the right to ask
                        us to transfer your data to you, or any other third
                        party.
                        <br /> <br />
                        If you would like to opt out of future communications
                        from a QCIL business or a QCIL program, please contact
                        us by clicking on the “Contact Us” link on the Web site
                        you are visiting. In all communications to QCIL, please
                        include the e–mail address used for registration (if
                        applicable), the Website address and explanation of your
                        request. If you would like to delete or amend your
                        personal information and are contacting us by e-mail,
                        please put “Deletion Request” or “Amendment Request”, as
                        applicable, in the subject line of the e-mail. We will
                        do our best to respond to all reasonable requests in a
                        timely manner. To exercise any of your right mentioned
                        in this policy, kindly do reach out via the Contact Us
                        link on the Website.
                      </p>
                    </div>

                    {/* Complaints and Grievances */}
                    <div className="text-holder">
                      <h1 className="title">Complaints and Grievances</h1>
                      <p>
                        Any complaints, abuse or concerns with regards to the
                        processing of personal data provided by you or breach of
                        these terms must be reported to frontdesk@QCIL.co.ug
                      </p>
                    </div>

                    {/* Change in Policy */}
                    <div className="text-holder">
                      <h1 className="title">Change in Policy</h1>
                      <p>
                        QCIL reserves the right to amend this Privacy Policy at
                        any time without prior notice to reflect technological
                        advancements, legal and regulatory changes and good
                        business practices. If QCIL changes its privacy
                        practices, a new Privacy Policy will reflect those
                        changes and the effective date of the revised Privacy
                        Policy will be set forth in this paragraph. <br />{" "}
                        <br /> This Privacy Policy is effective as of 1st August
                        2020.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="Whistle -Blowers Policy"
                title="Whistle -Blowers Policy"
              >
                <div className="row justify-content-center">
                  <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
                    {/* Preamble */}
                    <div className="text-holder">
                      <h1 className="title">Preamble</h1>
                      <p>
                        The Whistle-Blowers Protection Act, of 2010 protects
                        employees against dismissal, penalization, or any kind
                        of discrimination by their employers as a result of
                        disclosure of illegal, irregular or corrupt practices.
                        The Company has put in place this Policy to encourage
                        open communication and disclosure of any malpractice.
                        Employees who make disclosures without malice and in
                        good faith ("bona fide") are protected under this Act.
                        This Policy lays out a mechanism through which
                        irregular, corrupt, or illegal practices may be
                        disclosed and managed to address any risk that may be
                        posed to the Company and its stakeholders. It aims to
                        provide an avenue for employees to raise concerns and
                        reassurance that they will be protected from reprisals
                        or victimization for whistleblowing. This Whistleblowing
                        Policy is intended to cover protections for employees
                        who raise concerns regarding QCIL.
                      </p>
                    </div>

                    {/* Interpretation And Definitions */}
                    <div className="text-holder">
                      <h1 className="title">Interpretation And Definitions</h1>
                      <p>In this Policy</p>
                      <ul>
                        <li>
                          <p>
                            clause headings are for convenience only and are not
                            to be used in its interpretation;
                          </p>
                        </li>
                        <li>
                          <p>an expression which denotes</p>
                          <ul className="list-style-none">
                            <li>
                              <p><b>-</b> any gender includes the other genders;</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> a natural person includes a juristic person
                                and vice versa;
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            the singular includes the plural and vice versa.
                          </p>
                        </li>
                        <li>
                          <p>
                            Any reference to any statute, regulation, or other
                            legislation shall be a reference to That statute,
                            regulation, or other legislation as amended or
                            substituted from time to Time.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            Terms not otherwise defined in this Policy shall
                            bear the meaning assigned to them in the Act, Unless
                            the context indicates a contrary intention, the
                            following expressions shall bear the meanings set
                            opposite them below and cognate expressions shall
                            bear corresponding meanings:
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                              <b>-</b> <b>"Act"</b> means the Whistle Blowers Protection
                                Act, 2010;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Audit and Risk Committee"</b> means QCIL's Audit
                                and Risk Committee;
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> <b>"Board"</b> means the board of QCIL</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"QCIL"</b> or "Company" means QCIL Quality
                                Chemical Industries Ltd;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Human Resource Policy"</b> means QCIL’s Human
                                Resource Policy issued and approved by the Board
                                as reviewed or amended from time to time;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Companies Act"</b> means the Companies Act, 2012;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Good faith”</b> means the honest intent to act
                                without taking an unfair advantage over another
                                and includes honesty, fairness, lawfulness, and
                                absence of any intent to defraud;
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> <b>"Policy"</b> means this Whistleblower Policy;</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Protected Disclosure”</b> means a concern or
                                disclosure by the Whistleblower made following
                                this Policy or the Act;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Whistleblower"</b> means to disclose an act
                                irregular or illegal act;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> <b>"Whistleblower"</b> means a person who has
                                performed an act of whistleblowing.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    {/* Objectives Of This Policy */}
                    <div className="text-holder">
                      <h1 className="title">Objectives Of This Policy</h1>
                      <p>This Policy aims to —</p>
                      <ul>
                        <li>
                          <p>
                            {" "}
                            This Policy applies to all Board Members, Partners,
                            Staff Consultants, Independent Contractors, Service
                            Providers, and any other Stakeholders of whatever
                            nature dealing with the Company.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            This Policy shall cover concerns which include but
                            are not limited to;
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                              <b>-</b> Misappropriation of funds, fraud, or extortion
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Incorrect financial reporting</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> Dangers to Health and Safety or the
                                Environment
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Criminal activity/offenses</p>
                            </li>
                            <li>
                              <p><b>-</b> Violations of law or regulations</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> Breach of staff code of conduct, or any QCIL
                                Policy
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Improper conduct or unethical behavior</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> Misuse of company property or deliberate
                                destruction of company property
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Conflict of interest</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> Discrimination or unwarranted detrimental
                                treatment of employees (e.g. bullying, sexual
                                harassment as stipulated in the Human Resource
                                Manual)
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> The deliberate provision of misleading
                                information or falsification of records
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> Unlawful or unethical conduct relating to the
                                workplace
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Attempts to conceal any of the above</p>
                            </li>
                            <li>
                              <p><b>-</b> Attempts to carry out any of the above</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    {/*Policy Statement */}
                    <div className="text-holder">
                      <h1 className="title">Policy Statement</h1>

                      <ul>
                        <li>
                          <p>
                            QCIL is committed to the highest standards of
                            accountability. An important aspect of
                            accountability is a mechanism that enables staff to
                            voice concerns responsibly and effectively.
                          </p>
                        </li>
                        <li>
                          <p>
                            QCIL is committed to the highest standards of
                            accountability. An important aspect of
                            accountability is a mechanism that enables staff to
                            voice concerns responsibly and effectively.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Company supports the responsible reporting of
                            improper conduct and such reporting is supported and
                            encouraged under this Policy.
                          </p>
                        </li>
                        <li>
                          <p>
                            All disclosures will be responded to respectfully,
                            consistently, and fairly. All concerns and breaches
                            reported shall be investigated and action taken as
                            appropriate.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Company shall protect employees who disclose
                            concerns or lodge complaints in good faith and in
                            the reasonable belief that a malpractice has been
                            committed.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Company shall treat all such disclosures
                            confidentially and sensitively. The identity of the
                            individual making the allegation shall be kept
                            confidential.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Safeguards */}
                    <div className="text-holder">
                      <h1 className="title">Safeguards</h1>
                      <ul>
                        <li>
                          <p>
                            <b>Harassment or Victimization -</b> Harassment or
                            victimization for reporting concerns under this
                            Policy will not be tolerated.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b> Confidentiality -</b> Every effort will be made
                            to treat the complainant's identity with appropriate
                            regard for confidentiality
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Reporting */}
                    <div className="text-holder">
                      <h1 className="title">Reporting</h1>
                      <p>
                        A complaint or disclosure shall be made in writing by
                        the employee and shall contain as far as practicable;
                      </p>
                      <ul>
                        <li>
                          <p>
                            {" "}
                            the full name and address of the whistleblower,
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            nature of the malpractice in respect of which the
                            disclosure is made,
                          </p>
                        </li>
                        <li>
                          <p>
                            the name and particulars of the person alleged to
                            have committed, who is committing or is about to
                            commit the malpractice,
                          </p>
                        </li>
                        <li>
                          <p>
                            the time and place where the alleged impropriety is
                            taking place took place or is likely to take place,
                          </p>
                        </li>
                        <li>
                          <p>
                            the full name, address, and description of a person
                            who witnessed the Commission of the impropriety,
                          </p>
                        </li>
                        <li>
                          <p>
                            whether the whistleblower has disclosed the same or
                            some other impropriety on a previous occasion and if
                            so, about whom and to whom the disclosure was made;
                          </p>
                        </li>
                        <li>
                          <p>any other facts relevant to the disclosure.</p>
                        </li>
                      </ul>
                    </div>

                    {/* Reporting Procedure */}
                    <div className="text-holder">
                      <h1 className="title">Reporting Procedure</h1>
                      <ul>
                        <li>
                          <p>
                            <b>Internal Disclosure : </b>In reporting any
                            malpractice internally, the following steps shall be
                            followed:
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                                <b>Step 1:</b> immediate manager/ supervisor.
                              </p>
                            </li>

                            <li>
                              <p>
                                <b>Step 2:</b> if, after raising the concern with
                                your immediate manager/supervisor, you continue
                                to have reasonable grounds to believe the
                                concern was not addressed or if you are not
                                comfortable speaking with your immediate
                                manager/supervisor, you should report your
                                concern to the Human Resource Manager, Company
                                Secretary, the Chief Executive Officer or the
                                manager a level above your supervisor.
                              </p>
                            </li>

                            <li>
                              <p>
                                <b>Step 3:</b> if the above channels have been
                                followed and you still have concerns, or if you
                                feel that the matter is so serious that you
                                cannot discuss it with any of the above, or if
                                you are not comfortable speaking with your
                                immediate manager/supervisor, you should report
                                to the Chairperson of the Board Audit and Risk
                                Committee.
                              </p>
                            </li>

                            <li>
                              <p>
                                {" "}
                                <b>Step 4:</b> if the above channels have been
                                followed and you still have concerns, or if you
                                feel that the matter is so serious that you
                                cannot discuss it with any of the above, or if
                                you are not comfortable speaking with the
                                Chairperson of the Board Audit and Risk
                                Committee, you may raise the matter in good
                                faith by using the Hotline
                                .........................
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>

                      <p>
                        The Whistleblower hotline shall be available 24/7. All
                        calls shall be received by the Agent.
                      </p>

                      <p>
                        Upon reporting a disclosure through the hotline, the
                        call shall be received by an agent who shall compile a
                        report. The report shall be submitted to the Company
                        within 24 hours of receipt of a disclosure.
                      </p>

                      <p>
                        The Agent shall record the details of the whistleblower
                        which shall be used in providing feedback. The
                        Whistleblower details shall not be disclosed to the
                        Company by the Agent.
                      </p>

                      <p>
                        Should there be a need to obtain further details from
                        the Whistleblower, the Agent shall contact the
                        Whistleblower.
                      </p>

                      <p>
                        Should there be a need for the whistleblower to testify
                        at a hearing of a matter relating to the disclosure, the
                        Agent shall contact the Whistleblower to testify.
                      </p>

                      <p>
                        The whistleblower shall not be compelled to testify in
                        any matter and shall be free to decline any of the
                        Company's requests.
                      </p>
                    </div>

                    {/* External Disclosure */}
                    <div className="text-holder">
                      <h1 className="title">External Disclosure</h1>
                      <p>
                        Any complaints, abuse or concerns with regards to the
                        processing of personal data provided by you or breach of
                        these terms must be reported to frontdesk@QCIL.co.ug
                      </p>
                      <ul>
                        <li>
                          <p>
                            {" "}
                            External disclosures of any malpractice shall be
                            made by the Whistle Blowers Protection Act, 2010 as
                            may be amended from time to time.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            External disclosures may be made in the following
                            instances.
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                              <b>-</b> where the complaint does not pertain to the
                                whistleblower's employment;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> where the whistleblower reasonably believes
                                that he or she will be subjected to occupational
                                detriment if he or she discloses to his or her
                                employer;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> where the whistleblower reasonably believes or
                                fears that evidence relating to the impropriety
                                will be concealed or destroyed if he or she
                                makes the disclosure to his or her employer; or
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> where the complaint has already been made and
                                no action has been taken or the whistleblower
                                reasonably believes or fears that the employer
                                will take no action.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            {" "}
                            External disclosures may be made to any of the
                            following institutions—
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p><b>-</b> the Inspectorate of Government;</p>
                            </li>
                            <li>
                              <p><b>-</b> the Directorate of Public Prosecutions;</p>
                            </li>
                            <li>
                              <p><b>-</b> the Uganda Human Rights Commission;</p>
                            </li>
                            <li>
                              <p><b>-</b> the Directorate for Ethics and Integrity;</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> the Office of the Resident District
                                Commissioner;
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> Parliament of Uganda;</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> the National Environment Management Authority;
                                and
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> the Uganda Police Force.</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>

                    {/* Investigating Procedure */}
                    <div className="text-holder">
                      <h1 className="title">Investigating Procedure</h1>
                      <ul>
                        <li>
                          <p>
                            The Company shall immediately upon receipt of a
                            disclosure or within 7 (seven) days from receipt of
                            a disclosure respond to the Whistleblower
                            acknowledging receipt of a disclosure.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            All disclosures made under Step 1 to Step 4 shall be
                            forwarded to the Head of Internal Audit for
                            investigation.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Company shall within 21 days from receipt of a
                            disclosure:
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                              <b>-</b> decide whether to investigate the matter or
                                refer the matter to an alternative person or
                                body for investigation;
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> should a decision not to investigate be made,
                                the reasons for the decision shall be provided
                                to the Whistleblower, where possible.
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> All investigations shall be undertaken by the
                                Internal Audit Department, unless where the
                                disclosure has been referred to an alternative
                                person/body to investigation.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            Should the disclosure be referred to an alternative
                            person or body to investigate, the alternative body
                            or person shall;
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p>
                              <b>-</b> consider whether to investigate the report or
                                not. Should it decide not to investigate,
                                reasons for the same shall if possible, be
                                provided to the Whistleblower.
                              </p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> provide a time frame within which the
                                investigation will be completed.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            Should the Company, person, or body to which the
                            report was referred be unable to determine the
                            matter within the time frame indicated, it must
                            advise the Whistleblower of such and regularly or at
                            intervals advise that a decision is still pending,
                            but must in writing inform the Whistleblower of its
                            decision within 6 (six) months from the date the
                            disclosure was made.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            Should the Company, person, or body to which the
                            report was referred be unable to determine the
                            matter within the time frame indicated, it must
                            advise the Whistleblower of such and regularly or at
                            intervals advise that a decision is still pending,
                            but must in writing inform the Whistleblower of its
                            decision within 6 (six) months from the date the
                            disclosure was made.
                          </p>
                        </li>
                        <li>
                          <p>
                            Should the Whistleblower be willing to give evidence
                            at disciplinary, civil, and/or criminal proceedings,
                            he/she will be allowed to be accompanied by an
                            individual of his/her choice, subject to legal
                            requirements.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Investigation Finding */}
                    <div className="text-holder">
                      <h1 className="title">Investigation Finding</h1>
                      <ul>
                        <li>
                          <p>
                            A report of the investigation shall be submitted to
                            the Human Resource Manager, Chief Executive Officer,
                            and the Board Audit and Risk Committee depending on
                            the gravity of the malpractice.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            Where the disclosure is found to be justified and
                            malpractice identified, disciplinary proceedings
                            shall be instituted against the accused and action
                            taken following the Human Resource Policy or any
                            other applicable Company policy. Where the
                            malpractice identified involves a non—employee or
                            external person, action to be taken shall be
                            dependent on any Agreements in place and the Law.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Unsatisfactory Outcome */}
                    <div className="text-holder">
                      <h1 className="title">Unsatisfactory Outcome</h1>
                      <p>
                        In the event a Whistleblower is dissatisfied with the
                        outcome of the report, and he/she is still of the
                        opinion and reasonably believes that the information
                        disclosed and the allegation are substantially true,
                        he/she will be at liberty to decide to take the matter
                        further under the Whistle Blowers Protection Act, 2010
                        or any other law applicable.
                      </p>
                    </div>

                    {/* Protection Of The Whistle Blower */}
                    <div className="text-holder">
                      <h1 className="title">
                        Protection Of The Whistle Blower
                      </h1>
                      <p>
                        In the event a Whistleblower is dissatisfied with the
                        outcome of the report, and he/she is still of the
                        opinion and reasonably believes that the information
                        disclosed and the allegation are substantially true,
                        he/she will be at liberty to decide to take the matter
                        further under the Whistle Blowers Protection Act, 2010
                        or any other law applicable.
                      </p>

                      <ul>
                        <li>
                          <p>
                            {" "}
                            A Whistle Blower who discloses in good faith,
                            reasonably believing it to be true, shall not as a
                            consequence of making the disclosure, be victimized
                            or subjected to the following:
                          </p>
                          <ul className="list-style-none">
                            <li>
                              <p><b>-</b> any disciplinary action;</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> dismissal, suspended, demoted, harassed, or
                                intimidated,
                              </p>
                            </li>
                            <li>
                              <p><b>-</b> transferred against his/her will;</p>
                            </li>
                            <li>
                              <p><b>-</b> refused a transfer or a promotion;</p>
                            </li>
                            <li>
                              <p>- refused a transfer or a promotion;</p>
                            </li>
                            <li>
                              <p><b>-</b> threatened with any of the above; or</p>
                            </li>
                            <li>
                              <p>
                              <b>-</b> otherwise adversely affected in his/her
                                employment, including employment opportunities
                                and work security.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            {" "}
                            Any person who subjects an employee to any form of
                            occupational detriment on account or partly on
                            account of having made a Protected Disclosure, shall
                            be subjected to disciplinary action.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Disclosure In Bad Faith */}
                    <div className="text-holder">
                      <h1 className="title">Disclosure In Bad Faith</h1>
                      <ul>
                        <li>
                          <p>
                            {" "}
                            The Company Secretary shall ensure that adequate
                            records on whistleblowing activities are maintained.
                          </p>
                        </li>
                        <li>
                          <p>
                            QCIL recognizes that investigation of concerns and
                            breaches raised are likely to lead to the processing
                            of sensitive personal information of the accused.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Policy Implementation */}
                    <div className="text-holder">
                      <h1 className="title">Policy Implementation</h1>
                      <p>
                        Management is responsible for ensuring adequate
                        awareness about the scope and objective of this Policy,
                        including the platforms created for disclosure
                        internally. Awareness shall be created during staff
                        training/sensitization sessions, induction, through
                        email, and any communication platform that may be put in
                        place from time to time.
                      </p>
                    </div>

                    {/* Non-compliance With This Policy */}
                    <div className="text-holder">
                      <h1 className="title">Non-compliance With This Policy</h1>
                      <ul>
                        <li>
                          <p>
                            {" "}
                            Any person who violates relevant aspects of this
                            Policy will be subject to disciplinary action per
                            the Laws of Uganda and the Human Resource Policy.
                          </p>
                        </li>
                        <li>
                          <p>
                            {" "}
                            Any disciplinary action or penalty shall be without
                            prejudice to any civil and/or criminal consequences
                            that the violation may give rise to.
                          </p>
                        </li>
                      </ul>
                    </div>

                    {/* Review And Approval */}
                    <div className="text-holder">
                      <h1 className="title">Review And Approval</h1>
                      <ul>
                        <li>
                          <p>
                            This Policy shall be reviewed annually and amended
                            when the need arises.
                          </p>
                        </li>
                        <li>
                          <p>
                            The Policy shall come into effect on the date of
                            approval of this Policy by the Board.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacypolicy;
