import {React, useEffect} from 'react'
import "./awards.css"

const Awards = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  return (
    <>
     <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our People</h1>
        </div>
      </section>

        <div className='section-common-title'>
            <h6 className='sec-title'>Awards & Rewards</h6>
        </div>

        <section className='awards-rewards-section'>
        
            <div className='container'>
                <div className='troffi-sec'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                        <div className='col-xxl-3 col-md-4'>
                            <div className='img-div'>
                                <img className='award-image' src={process.env.PUBLIC_URL + '/assets/image/our-people/awards/award.png'} alt="award-image" />
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </section>
    </>
  )
}

export default Awards;