import { useState, useEffect } from "react";
import "./leadershipteam.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const Leadershipteam = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
     <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our People</h1>
        </div>
      </section>

      
      <section className="leadership-team-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <div className="leadership-bg">
                <div className="section-common-title">
                  <h6 className="sec-title">Leadership Team </h6>
                </div>

                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-4 col-md-4  mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/ajay_pal_profile.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Ajay Kumar Pal
                          </h6>
                          <p className="member-position">
                          Chief Executive Officer / Executive Director
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4  mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/Frederick_Andrew_Kakooza.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name"  onClick={handleShow}>
                          Frederick Andrew Kakooza
                          </h6>
                          <p className="member-position">
                          Chief Financial Officer (CFO)
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/Awanga_Doreen.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Doreen Awanga
                          </h6>
                          <p className="member-position">
                          Company Secretary
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4  mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/Harrison_Kigundu.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Harrison Kigundu
                          </h6>
                          <p className="member-position">
                          Human Resources
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/Atul_Vadepalli.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Atul Vadepalli
                          </h6>
                          <p className="member-position">
                          Quality Assurance Manager
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4  mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/picture_profile.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Frederick Kitaka Mutebi
                          </h6>
                          <p className="member-position">
                          Company Pharmacist
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4  mb-4">
                      <div className="leader-card-sec">
                        <div className="members-card-div">
                          <div
                            className="profile-img-div"
                            data-bs-toggle="modal"
                            data-bs-target="#memberdescriptionmodal"
                          >
                            <img
                              className="member-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/our-people/Leadership/picture_profile.png"
                              }
                              alt="members"
                            />
                          </div>
                          <h6 className="member-name" onClick={handleShow}>
                          Frederick Kitaka Mutebi
                          </h6>
                          <p className="member-position">
                          Designation will be given
                          </p>

                          <div className="btn-holder" id="center-btn" onClick={handleShow}>
                            <button className="trans-read-more icon-position">
                              <FontAwesomeIcon
                                className="trans-read-more-btn"
                                icon="fa-solid fa-arrow-right"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* offcanvas section start */}
        <div className="top-offcanvas">
          <div className="container-fluid">
            <Offcanvas
              className="impact-offcanvas"
              show={show}
              placement="top" // Set the placement to "top" for top position
              onHide={handleClose}
            >
              <div className="bg-img-holder">
                <img
                  className="offcanvas-bg-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/image/our-impact/offcanvas_bg/bg_img.png"
                  }
                  alt="offcanvas-bg-img"
                />
              </div>
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="impact-modal">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-lg-5 col-md-6">
                        <div className="img-div">
                          <div className="slider-content">
                            <div className="row">
                              <div className=" col-md-12 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/our-people/board-members/offcanvas_member.png"
                                    }
                                    alt="offcanvas"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6">
                        <div className="text-holder">
                          <p>Board Chairman / Executive Director</p>
                          <h1 className="title">Emmanuel Katongole</h1>
                          <p>
                            Mr Katongole was a Founding Chief Executive Officer
                            of QCIL Quality Chemical Industries Limited and was
                            promoted to the position of Executive Chairman in
                            November 2013. Previously, he served as Managing
                            Director and shareholder of Quality Chemicals
                            Limited (the local firm that partnered with QCIL to
                            form QCIL). He has a wealth of experience in
                            senior management and leadership positions. Mr
                            Katongole is also the Founder of Vero Food
                            Industries – a food and beverage company; an
                            independent Non-Executive Director of Barclays Bank
                            (U) Ltd; Chairman of the Uganda National Oil Company
                            and Chairman of the Advisory Board of London based
                            TLG Capital Ltd. He is a member of the Initiative
                            for Global Development (Frontier 100) – a
                            prestigious group that joins the most successful
                            business leaders operating in frontier markets.{" "}
                            <br /> <br /> In addition to numerous nominations,
                            Mr Katongole has won several business awards. He was
                            the East African Winner and representative at the
                            2013 Ernst and Young World Entrepreneur of the Year
                            Awards in Monte Carlo, bagged the 2012 Africa
                            Business Leadership Award and the 2012 Africa
                            Entrepreneurship Award. <br /> <br /> He is a
                            Rotarian who has steadily and diligently served as
                            District Governor for Rotary District 9211 which
                            comprises of Tanzania and Uganda and currently
                            serves as Endowment and Major Gifts Advisor for
                            Africa. He is a multiple Major Donor to the Rotary
                            Foundation. <br /> Mr Katongole holds a Master of
                            Arts Degree in Economic Policy and Planning and an
                            Honours Bachelor of Statistics degree, both from
                            Makerere University.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>

        {/* start memberdescriptionmodal modal  */}
        <div
          className="same-modal modal fade"
          id="memberdescriptionmodal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <button
                id="black-close"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <div className="img-holder">
                  <img
                    className="modal-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/img_modal/modal_img.png"
                    }
                    alt="modal-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end memberdescriptionmodal modal  */}
        {/* offcanvas section end */}
      </section>
    </>
  );
};

export default Leadershipteam;
