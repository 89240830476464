import {React, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import "./Worth.css";

function Worth() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div className="main">
      <section className="Worth">
        <div className="Worth-content">
          {/* bg-img section start */}
          <div className="bg-img"> 
          <img className="bg" src={ process.env.PUBLIC_URL + "assets/image/Home/Worth/bg_img.png" } alt="..." />
           </div>
          {/* bg-img section end */}
          {/* inner content start */}
          <div className="content-holder">
            <div className="container">
              <div className="inner-content">
                <div className="row justify-content-center">
                  <div className=" col-md-5 col-6">
                  <div className="worth-img-bg">
                    {/* <div className="bg-img-holder">
                    <img
                        className="worth-bg-img"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Worth/Worth _img_bg.png"
                        }
                        alt="Worth-bg-img"
                      />
                    </div> */}
                    <div className="img-holder">
                      <img
                        className="worth-img"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/image/Home/Worth/Worth_img.png"
                        }
                        alt="Worth-img"
                      />
                    </div>
                    
                  </div>
                  </div>
                  <div className=" col-md-5 col-6">
                    <div className="text-holder">
                        <h5 className="title">A life</h5>
                        <h1 className="title">Worth Living</h1>
                        <p>At Quality Chemical Industries Limited (QCIL), we are not mere manufacturers of medication. What we make are memories. Memories that may not have been there to be remembered, had a life not been lived. <br /> <br /> We are the largest pharmaceutical manufacturer in East Africa and also one of the largest in Sub-Saharan Africa (SSA), QCIL is one of the few pharmaceutical manufacturers in SSA to operate World Health Organization (WHO) cGMP-compliant facilities that manufactures a range of WHO pre-qualified medicines for treating HIV/AIDS and malaria.</p>
                    </div>
                    <div className="purple-read-more">
                    <Button className="purple-read-more-btn">QCIL - Read More</Button>{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* inner content end */}
        </div>
      </section>
    </div>
  );
}

export default Worth;
