import { useState, useEffect } from "react";
import "../../stepform.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

function Academicformstepfive() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [item, setItem] = useState({  kindOfStand: [], another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) =>  (e, setIndex) => {
    e.persist();
    const { value, checked } = e.target;

    setItem((prevState) => {
      const updatedSets = [...prevState.kindOfStand];
      if (checked)  {
        // If checkbox is checked, add the value to the corresponding set
        updatedSets[setIndex] = updatedSets[setIndex]
          ? [...updatedSets[setIndex], value]
          : [value];
      } else {
        // If checkbox is unchecked, remove the value from the corresponding set
        updatedSets[setIndex] = updatedSets[setIndex]
          ? updatedSets[setIndex].filter((v) => v !== value)
          : [];
      }

      return {
        ...prevState,
        kindOfStand: updatedSets,
      };
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
    
    <>
      <section className="formm-sec" id="bottom-space-2">
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">
                      Computer Knowledge/Software Known
                    </label>
                  </div>

                  <div className="">
                    <textarea
                      rows={1}
                      required
                      type="text"
                      placeholder="Enter Non-Occupational Activities"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Languages Known</label>
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex space-between" id="checkbox-mb">
                  <Form.Control
                    id="language-width"
                    required
                    type="text"
                    placeholder="Enter Language Known"
                  />

                  <div className="checkbox">
                    <div className="type">
                      <Form.Check
                       value="Read"
                        aria-label="checkbox 1"
                        label="Read"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Read")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                      value="Write"
                        aria-label="checkbox 2"
                        label="Write"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Write")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                       value="Speak"
                        aria-label="checkbox 3"
                        label="Speak"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Speak")}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="d-flex gender-flex space-between" id="checkbox-mb">
                  <Form.Control
                    id="language-width"
                    required
                    type="text"
                    placeholder="Enter Language Known"
                  />

                  <div className="checkbox">
                    <div className="type">
                      <Form.Check
                       value="Read"
                        aria-label="checkbox 1"
                        label="Read"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Read")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                      value="Write"
                        aria-label="checkbox 2"
                        label="Write"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Write")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                       value="Speak"
                        aria-label="checkbox 3"
                        label="Speak"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Speak")}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex gender-flex space-between" id="checkbox-mb">
                  <Form.Control
                    id="language-width"
                    required
                    type="text"
                    placeholder="Enter Language Known"
                  />

                  <div className="checkbox">
                    <div className="type">
                      <Form.Check
                       value="Read"
                        aria-label="checkbox 1"
                        label="Read"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Read")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                      value="Write"
                        aria-label="checkbox 2"
                        label="Write"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Write")}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                       value="Speak"
                        aria-label="checkbox 3"
                        label="Speak"
                        onChange={(e) => handleChange(e, 0)}
                        checked={kindOfStand[0]?.includes("Speak")}
                      />
                    </div>
                  </div>
                </div>
            
              </Form.Group>
              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex end">
                  <div className="type">
                    <label className="form-label" id="underline">
                      Add More
                    </label>
                  </div>
                </div>
              </Form.Group>
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
}

export default Academicformstepfive;
