import { React, useEffect } from "react";
import "./life.css";

import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

const Life = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our People</h1>
        </div>
      </section>

      <section className="life-at-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-1">
                <h5 className="qcil-title">Life at QCIL</h5>
                <p className="sub-content">
                  At Quality Chemical Industries Limited (QCIL), we are not mere
                  manufacturers of medication. What we make are memories.
                  Memories that may not have been there to be remembered, had a
                  life not been lived.
                </p>
                <p className="sub-content">
                  We are the largest pharmaceutical manufacturer in East Africa
                  and also one of the largest in Sub-Saharan Africa (SSA), QCIL
                  is one of the few pharmaceutical manufacturers in SSA to
                  operate World Health Organization (WHO) cGMP-compliant
                  facilities that manufactures a range of WHO pre-qualified
                  medicines for treating HIV/AIDS and malaria. We manufacture
                  antiretrovirals, artemisinin-based combination therapies
                  (ACTs) and Hepatitis medicines to treat HIV/AIDS, malaria and
                  Hepatitis.
                </p>
              </div>
            </div>
            <div className="col-md-5  my-auto">
              <div className="section-2">
                <img
                  className="group-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/image/our-people/life_at/Group.png"
                  }
                  alt="group-img"
                />
              </div>
            </div>
          </div>

          <div className="qcil-members-section">
            <div className="sliders-div">
              <Swiper
                className="qcil-swiper"
                navigation={{}}
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}
                loop={true}
                slidesPerView={1}
                breakpoints={{
                  "0px": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="qcil-members-card">
                    <div className="img-div">
                      <img
                        className="qcl-member-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/our-people/life_at/dummy-user.png"
                        }
                        alt="member"
                      />
                    </div>

                    <div className="overflow-color"></div>
                    <div className="member-details">
                      <h6 className="member-name">DummyName</h6>
                      <p className="member-position">DummyPosition</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="qcil-members-card">
                    <div className="img-div">
                      <img
                        className="qcl-member-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/our-people/life_at/dummy-user.png"
                        }
                        alt="member"
                      />
                    </div>

                    <div className="overflow-color"></div>
                    <div className="member-details">
                      <h6 className="member-name">DummyName</h6>
                      <p className="member-position">DummyPosition</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="qcil-members-card">
                    <div className="img-div">
                      <img
                        className="qcl-member-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/our-people/life_at/dummy-user.png"
                        }
                        alt="member"
                      />
                    </div>

                    <div className="overflow-color"></div>
                    <div className="member-details">
                      <h6 className="member-name">DummyName</h6>
                      <p className="member-position">DummyPosition</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="qcil-members-card">
                    <div className="img-div">
                      <img
                        className="qcl-member-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/our-people/life_at/dummy-user.png"
                        }
                        alt="member"
                      />
                    </div>

                    <div className="overflow-color"></div>
                    <div className="member-details">
                      <h6 className="member-name">DummyName</h6>
                      <p className="member-position">DummyPosition</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="qcil-members-card">
                    <div className="img-div">
                      <img
                        className="qcl-member-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/our-people/life_at/dummy-user.png"
                        }
                        alt="member"
                      />
                    </div>

                    <div className="overflow-color"></div>
                    <div className="member-details">
                      <h6 className="member-name">DummyName</h6>
                      <p className="member-position">DummyPosition</p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Life;
