import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const Footer = () => {
  return (
    <>
      <section className="main-footer" id="footer-body">
        <div className="footer">
          <div className="container">
            {/* footer link section start */}
            <div className="footer-content">
              <div className="row  me-0 ms-0">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Our Company</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/who-we-are/vision"}>
                          <p>Who we are</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our-history/foundingdirector"}>
                          <p>Our History</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our-people/board-members"}>
                          <p>Our People</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_company/trade"}>
                          <p>Partnerships</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_company/where_to_find"}>
                          <p>Where to found us</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">About Us</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/about_us/founder"}>
                          <p>Founders</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Quick Facts</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Our Products</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/our_product/product_portfolio"}>
                          <p>Product Portfolio</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_product/hiv_aids"}>
                          <p>Therapy Areas</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_product/accreditations"}>
                          <p>Quality</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Newsroom</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/newsroom/news"}>
                          <p>News</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Bullet-ins Newsletters</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/newsroom/mediacontacts"}>
                          <p>Media Contact</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/newsroom/library"}>
                          <p>Media Library</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Our Impact</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/our_impact/access"}>
                          <p>Access to medicine</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_impact/ethics"}>
                          <p>Ethics and compliance</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_impact/responsible"}>
                          <p>Responsible manufacturing</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_impact/responsible"}>
                          <p>Environmental sustainability</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/our_impact/corporate"}>
                          <p>Corporate Social responsibility</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Investor Relations</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/"}>
                          <p>‘Why invest’ pitch</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Share Price Trend</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/financialreports"}>
                          <p>Financial reports</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/annualreports"}>
                          <p>Annual reports</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/announcement"}>
                          <p>Announcements/Notices</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/performance"}>
                          <p>Performance</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/governance"}>
                          <p>Corporate governance</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Board committees / <br /> charters / policies</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Shareholder services / <br /> engagements</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/"}>
                          <p>Ïnvestor Relations contact</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/investor_relations/f&q"}>
                          <p>FAQ</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Careers</h4>
                    </div>
                    <ul>
                      <li>
                        <Link to={"/careers/career-opportunity"}>
                          <p>Career Opportunities</p>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/careers/hr_form"}>
                          <p>HR Form</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                  <div className="list">
                    <div className="footer-title">
                      <h4 className="title">Contact Us</h4>
                    </div>
                    <ul className="list-padding">
                      <li className="list-dots">
                        <Link to={""}>
                          <div className="contact">
                            <div className="icon">
                              <FontAwesomeIcon icon="fa-solid fa-phone" />
                            </div>
                            <div>
                              <p>+256 312 341 100</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="list-dots">
                        <Link to={"/"}>
                          <div className="contact">
                            <div className="icon">
                              <FontAwesomeIcon icon="fa-solid fa-envelope" />
                            </div>
                            <div>
                              <p>info@qcil.co.ug</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li className="list-dots">
                        <Link to={"/"}>
                          <div className="contact">
                            <div className="icon">
                              <FontAwesomeIcon icon="fa-solid fa-envelope" />
                            </div>
                            <div>
                              <p>drugsafety@qcil.com</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* footer link section end */}
            {/* copyrights section start */}
            <div className="copyright">
              <div className="privacy">
                <div className="privacy-Disclaimer">
                  <p>Privacy Policy</p>
                </div>
                <div className="privacy-Disclaimer">
                  <p>Disclaimer</p>
                </div>
              </div>
              <div className="copyright-link">
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6">
                    <div className="link">
                      <p>
                        © 2022 All Rights Reserved Terms Of Use | Privacy Policy
                        Designed by <a className="underline">Profcyma</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6">
                    <div className="social-icon">
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/facebook.png"
                          }
                          className="social"
                          alt="..."
                        />
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/linkedin.png"
                          }
                          className="social"
                          alt="..."
                        />
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/twitter.png"
                          }
                          className="social"
                          alt="..."
                        />
                      </div>
                      <div className="icon">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icons/social/youtube.png"
                          }
                          className="social"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* copyrights section end */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
