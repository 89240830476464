import { useState, useEffect } from "react";

import ReactDOM from "react-dom";
import MultiStep from "react-multistep";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import "./academic.css";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Academicformstepone from "./Academicstepone/Academicformstepone";
import Academicformsteptwo from "./Academicsteptwo/Academicformsteptwo";
import Academicformstepthree from "./Academicstepthree/Academicformstepthree";
import Academicformstepfour from "./Academicstepfour/Academicformstepfour";
import Academicformstepfive from "./Academicstepfive/Academicformstepfive";

function Academicstepform({ onNext, onPrev }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  //For manageing state of multi steps Form
  const [page, setPage] = useState(0);

  const nextStep = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    setPage((currPage) => currPage + 1);
    if (page === 4 && onNext) {
      onNext(); // Call the callback function passed from Stepform2
    }
  };

  const prevStep = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev(); // Call the callback function passed from Stepform2
    }
  };

  const calculatePercentage = () => {
    return ((page + 1) / 5) * 100; // Assuming there are 5 steps
  };

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <Academicformstepone nextStep={nextStep} prevStep={prevStep}/>;
      case 1:
        return <Academicformsteptwo nextStep={nextStep} prevStep={prevStep} />;
      case 2:
        return (
          <Academicformstepthree nextStep={nextStep} prevStep={prevStep} />
        );
      case 3:
        return <Academicformstepfour nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <Academicformstepfive nextStep={nextStep} prevStep={prevStep} />;
      default:
        return <Academicformstepone />;
    }
  };

  return (
    <>
      <div className="ProgressBar">
        <ProgressBar
          percent={calculatePercentage()}
          filledBackground="#562278"
          height="2px"
          style={{ margin: "auto" }}
        >
          {[1, 2, 3, 4, 5].map((step, index) => (
            <Step key={index}>
              {({ accomplished }) => (
                <div
                  style={{
                    backgroundColor: accomplished ? "#562278" : "#ffffff",
                    color: accomplished ? "#fff" : "#562278",
                    border: accomplished
                      ? "1px solid #562278"
                      : "1px solid #ddd", // Border for unfilled steps
                  }}
                  className={`step ${accomplished ? "completed" : null}`}
                >
                  {step}
                </div>
              )}
            </Step>
          ))}
        </ProgressBar>
      </div>
      <div className="userForm">
        <div className="">{PageDisplay()}</div>
        <div className="inner-step-nav-btn" style={{ textAlign: "center" }}>
          <button
            onClick={() => prevStep()}
            className="back-btn title"
            // disabled={page === 0} // Disable the button if it's the first step
          >
            Back
          </button>

          <button
            onClick={() => nextStep()} // Add this line to handle the "Next" button click
            className="next-btn title"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}

export default Academicstepform;
