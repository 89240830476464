import { useState, useEffect } from "react";
import "../impact_style/our_impact.css";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function EHSimpact() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  // const [show, setShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our Impact</h1>
        </div>
      </section>

      <section className="EHS-Sustainability">
        <div className="container">
          <div className="EHS-content">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="text-holder">
                  <h1 className="title">
                    Environment Health and Sustainability
                  </h1>
                  <p>
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    <br />
                    <br />
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                  </p>
                  <div className="purple-read-more">
                    <Button
                      className="purple-read-more-btn" id="no-shadow"
                      onClick={handleShow}
                    >
                      Read More
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6">
                <div className="img-holder">
                  <img
                    className="EHS-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our-impact/EHS/EHS.png"
                    }
                    alt="EHS-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-offcanvas">
          <div className="container">
            <Offcanvas
              className="impact-offcanvas"
              show={show}
              placement="top" // Set the placement to "top" for top position
              onHide={handleClose}
            >
            <div className="bg-img-holder">
            <img
            className="offcanvas-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-impact/offcanvas_bg/bg_img.png"
            }
            alt="offcanvas-bg-img"
          />
            </div>
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body>
                <div className="impact-modal">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-4">
                        <div className="img-div">
                          <div className="slider-content">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Home/Mission/img_3.png"
                                    }
                                    alt="site-tour"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row me-0 ms-0">
                              <div className="col-md-6 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Home/Mission/img_3.png"
                                    }
                                    alt="site-tour"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Home/Mission/img_3.png"
                                    }
                                    alt="site-tour"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Home/Mission/img_3.png"
                                    }
                                    alt="site-tour"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 p-0">
                                <div className="img-holder">
                                  <img
                                    className="site-tour-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/Home/Mission/img_3.png"
                                    }
                                    alt="site-tour"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-holder">
                          <h1 className="title">
                            Environment Health and Sustainability
                          </h1>
                          <p>
                            Placed Dummy Content here. Need content from
                            business. Placed Dummy Content here. Need content
                            from business. Placed Dummy Content here. Need
                            content from business. Placed Dummy Content here.
                            Need content from business. Placed Dummy Content
                            here. Need content from business.
                            <br />
                            <br />
                            Placed Dummy Content here. Need content from
                            business. Placed Dummy Content here. Need content
                            from business. Placed Dummy Content here. Need
                            content from business. Placed Dummy Content here.
                            Need content from business. Placed Dummy Content
                            here. Need content from business.
                            <br />
                            <br />
                            Placed Dummy Content here. Need content from
                            business. Placed Dummy Content here. Need content
                            from business. Placed Dummy Content here. Need
                            content from business. Placed Dummy Content here.
                            Need content from business. Placed Dummy Content
                            here. Need content from business.
                            <br />
                            <br />
                            Placed Dummy Content here. Need content from
                            business. Placed Dummy Content here. Need content
                            from business. Placed Dummy Content here. Need
                            content from business. Placed Dummy Content here.
                            Need content from business. Placed Dummy Content
                            here. Need content from business.
                            <br />
                            <br />
                            Placed Dummy Content here. Need content from
                            business. Placed Dummy Content here. Need content
                            from business. Placed Dummy Content here. Need
                            content from business. Placed Dummy Content here.
                            Need content from business. Placed Dummy Content
                            here. Need content from business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </section>
    </>
  );
}

export default EHSimpact;
