import {React,useEffect} from "react";
import "./Financialreports.css";
import Button from "react-bootstrap/Button";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

function Financialreports() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="Financialreports">
        <div className="container">
          <div className="report-heading">
            <h1 className="title">Financial</h1>
            <h2>Reports</h2>
          </div>
          
            
            <div className="finance-content">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-12">
              <Swiper
              className="financial-swiper"
              navigation={{
                nextE1: ".swiper-button-next", // CSS class for the next button
                prevE1: ".swiper-button-prev", // CSS class for the previous button
              }}
              pagination={{ clickable: true }}
              //   autoplay={{ delay: 3000 }}
              loop={false}
              slidesPerView={1} // Display one slide at a time
            >
               <SwiperSlide>
                <div className="finance-data-cards">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">May 2023</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Audited Financial Statements For FY 2022-23</p>
                        </div>
                        <div className="Download">
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2021</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Annual Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2023</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Full Audited Annual Financial Statements 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Financial Statements 2021-2022</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">17 November 2020</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="finance-data-cards">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">May 2023</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Audited Financial Statements For FY 2022-23</p>
                        </div>
                        <div className="Download">
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2021</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Annual Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2023</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Full Audited Annual Financial Statements 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Financial Statements 2021-2022</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">17 November 2020</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="finance-data-cards">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">May 2023</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Audited Financial Statements For FY 2022-23</p>
                        </div>
                        <div className="Download">
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2021</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Annual Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2023</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">November 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Full Audited Annual Financial Statements 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">31 March 2022</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Financial Statements 2021-2022</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="data-box">
                        <div className=" date-holder">
                          <p className="title">17 November 2020</p>
                        </div>
                        <div className="finance-text-holder">
                          <p className="title">Half Year Interim Financial Results 2020-2021</p>
                        </div>
                        <div className="Download" >
                          <Button className="Download-btn titte" id="no-hover">
                          Download
                          </Button>{" "}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                </SwiperSlide>
                
                </Swiper>
              </div>
            </div>
          </div>
            

         
        </div>
      </section>
    </>
  );
}

export default Financialreports;
