import {React, useEffect} from "react";
import "../../product_style/Product.css";
import Play from "../../../../animation/play_btn/Play";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);


function Malaria() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Therapy Areas</h1>
        </div>
      </section>

      <section className="Hivaids">
        <div className="main-holder">
          <div className="heading">
            <p className="title">Anti-infectives</p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="main-content-holder">
                  <div className="row justify-content-center">
                    <div className="col-md-7 ps-0">
                      <div className="text-content-holder">
                        <p>
                          “Caring for life” is an integral part of our ethos at
                          QCIL. We are firmly grounded in the belief that no
                          life should be lost, especially not to a treatable
                          disease. In 2018, the World Health Organization (WHO)
                          estimated that there were a staggering 228 million
                          malaria cases and 405,000 fatalities in the world.{" "}
                          <br /> <br /> “Caring for life” is an integral part of
                          our ethos at QCIL. We are firmly grounded in the
                          belief that no life should be lost, especially not to
                          a treatable disease. In 2018, the World Health
                          Organization (WHO) estimated that there were a
                          staggering 228 million malaria cases and 405,000
                          fatalities in the world.
                        </p>
                        <div className="main-img-div">
                          <div className="row justify-content-end">
                            <div className="col-md-5">
                            <div className="main-malaria-vdo-holder">
                            <div
                                className="vdo-holder"
                              >
                                <video
                                  autoPlay
                                  muted
                                  loop
                                  className="malaria-vdo"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/vedio/our_product/maleria/Malaria_Video.mp4"
                                  }
                                  alt="malaria"
                                />
                              </div>
                              <div className="malaria-vdo-play-btn" data-bs-toggle="modal"
                                data-bs-target="#memberdescriptionmodal" >
                                <Play/>
                              </div>
                            </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="sub-heading-holder">
                        <div className="sub-heading">
                          <p>Malaria</p>
                        </div>
                        <h1 className="title" id="right">
                          Live-saving treatments <br /> at an affordable price
                        </h1>
                        <vr class="border-dashed-2"></vr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* start memberdescriptionmodal modal  */}
        <div
          className="same-modal modal fade"
          id="memberdescriptionmodal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body">
                <video
                  autoPlay
                  muted
                  loop
                  className="malaria-vdo"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/vedio/our_product/maleria/CIPLA  UGANDA  - MALARIA VIDEO.mp4"
                  }
                  alt="malaria"
                />
              </div>
            </div>
          </div>
        </div>
        {/* end memberdescriptionmodal modal  */}
      </section>
    </>
  );
}

export default Malaria;
