import { React, useEffect } from "react";
import "./opportunity.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const Opportunity = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Careers</h1>
        </div>
      </section>

      <section className="opportunity-section">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-md-6">
              <div className="section-1">
                <p className="working-p title">Working at</p>
                <h2 className="cipla-title">QCIL</h2>
                <p className="content title">
                  CiplaQCIL is the first pharmaceutical manufacturing facility
                  of its kind in the region and is committed to its vision of
                  becoming a centre of excellence in manufacturing of quality,
                  affordable medicines in Africa.
                </p>
                <br />{" "}
                <p className="content title">
                  In that regard, CiplaQCIL employs professionals that are not
                  only skilled and knowledgeable but that are also passionate
                  about pharmaceuticals and making a difference. CiplaQCIL
                  currently has more than 350 employees that are dedicated to
                  quality and excellence.
                </p>
              <div className="butn">
              <div className="purple-read-more">
                <Link to="/career-form2">
                  <Button
                    className="purple-read-more-btn"
                    id="btn-size"                    
                  >
                    Apply
                  </Button>
                </Link>
              </div>
              </div>
              </div>
            </div>
            <div className="col-md-4 my-auto">
              <div className="section-2">
                <img
                  className="group-img"
                  src={
                    process.env.PUBLIC_URL + "/assets/image/career/Group.png"
                  }
                  alt="group"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="career-oppo-bg-section">
        <div className="container">
          <div className="row two-side-cards">
            <div className="col-md-6">
              <div className="content-div">
                <div className="bd-right"></div>
                <h4 className="career-title title">Career Opportunities</h4>
                <p className="content-p title">
                  At Cipla QCIL, we consider our people to be our most valuable
                  asset and we intend to be the employer of choice within the
                  pharmaceutical sector. We strive to empower our people to
                  realise their full potential and career aspirations through a
                  strong focus on continuous training, performance coaching,
                  personal development, and talent management. Our values are
                  that of respect and pride, passion and integrity, empowerment,
                  freedom to succeed, service excellence and social
                  responsibility.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content-div">
                <h4 className="career-title title">Internships</h4>
                <p className="content-p title">
                  CiplaQCIL offers an internship opportunity once a year to
                  third-year undergraduate students in Uganda. The internships
                  are designed to give students practical experience by exposing
                  them to world-class standards and challenging them to solve
                  problems and formulate ideas for improvement. Applications
                  usually open in the middle of the year.
                </p>

                <p className="content-p title">
                  For more info regarding internships, contact{" "}
                  <Link className="link-color" to="/">
                    {" "}
                    info@ciplaqcil.co.ug
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="job-rol-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="job-rol-card">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="pdf-img"
                      src={
                        process.env.PUBLIC_URL + "/assets/image/career/pdf.png"
                      }
                      alt="pdf-img"
                    />
                    <p className="job-p-role title">Job Role</p>
                  </div>
                  <div className="col-6">
                    <div className="view-pdf-main">
                      <Link className="view-pdf-p title" to="/">
                        View PDF
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="job-rol-card">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="pdf-img"
                      src={
                        process.env.PUBLIC_URL + "/assets/image/career/pdf.png"
                      }
                      alt="pdf-img"
                    />
                    <p className="job-p-role title">Job Role</p>
                  </div>
                  <div className="col-6">
                    <div className="view-pdf-main">
                      <Link className="view-pdf-p title" to="/">
                        View PDF
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="job-rol-card">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="pdf-img"
                      src={
                        process.env.PUBLIC_URL + "/assets/image/career/pdf.png"
                      }
                      alt="pdf-img"
                    />
                    <p className="job-p-role title">Job Role</p>
                  </div>
                  <div className="col-6">
                    <div className="view-pdf-main">
                      <Link className="view-pdf-p title" to="/">
                        View PDF
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="job-rol-card">
                <div className="row">
                  <div className="col-6">
                    <img
                      className="pdf-img"
                      src={
                        process.env.PUBLIC_URL + "/assets/image/career/pdf.png"
                      }
                      alt="pdf-img"
                    />
                    <p className="job-p-role title">Job Role</p>
                  </div>
                  <div className="col-6">
                    <div className="view-pdf-main">
                      <Link className="view-pdf-p title" to="/">
                        View PDF
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Opportunity;
