import { React, useEffect, useState } from "react";
import "./values.css";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Values = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Who We Are</h1>
        </div>
      </section>

      <section className="key-value-section">
        <div className="container-fluid p-0">
          <div className="bg-img-div">
            <img
              className="bg-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/image/our_company/values/banner.png"
              }
            />

            <div className="overlay-color"></div>

            <div className="overlay-content">
              <div className="container">
                <div className="values-main-div">
                  <div className="center-main-circle">
                    <p className="circle-name">
                      Key <br></br> Values
                    </p>
                  </div>

                  <div className="first-circle-pos">
                    <div className="d-flex " onClick={handleShow1}>
                      <div className="next-img-div my-auto">
                        <img
                          className="next"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/next-icon.png"
                          }
                        />
                      </div>
                      <div className="my-auto me-4">
                        <p className="key-name">Quality</p>
                      </div>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/favorites.png"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="second-circle-pos">
                    <div className="d-flex" onClick={handleShow2}>
                      <div className="next-img-div my-auto">
                        <img
                          className="next"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/next-icon.png"
                          }
                        />
                      </div>
                      <div className="my-auto me-4">
                        <p className="key-name">Excellence</p>
                      </div>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/excellence.png"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="third-circle-pos">
                    <div className="d-flex" onClick={handleShow3}>
                      <div className="next-img-div my-auto">
                        <img
                          className="next"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/next-icon.png"
                          }
                        />
                      </div>
                      <div className="my-auto me-4">
                        <p className="key-name">Accountability</p>
                      </div>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/list.png"
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="fourth-circle-pos">
                    <div className="" onClick={handleShow4}>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/teamwork.png"
                          }
                        />
                      </div>

                      <div className="d-flex justify-content-center align-item-center">
                        <div className="next-img-div my-auto text-center">
                          <img
                            className="next"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_company/values/next-icon.png"
                            }
                          />
                        </div>
                        <div className="my-auto">
                          <p className="key-name">Teamwork</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fifth-circle-pos">
                    <div className="d-flex" onClick={handleShow5}>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/integration.png"
                          }
                        />
                      </div>
                      <div className=" d-flex justify-content-center align-item-center">
                        <div className="next-img-div my-auto">
                          <img
                            className="next2"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_company/values/next-icon.png"
                            }
                          />
                        </div>
                        <div className="my-auto ms-4">
                          <p className="key-name">Integrity</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sixth-circle-pos">
                    <div className="d-flex" onClick={handleShow6}>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/customer-target.png"
                          }
                        />
                      </div>
                      <div className=" d-flex justify-content-center align-item-center">
                        <div className="next-img-div my-auto">
                          <img
                            className="next2"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_company/values/next-icon.png"
                            }
                          />
                        </div>
                        <div className="my-auto ms-4">
                          <p className="key-name">Customer Focus</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="seventh-circle-pos">
                    <div className="d-flex" onClick={handleShow7}>
                      <div className="sub-circle">
                        <img
                          className="inner-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/our_company/values/innovation.png"
                          }
                        />
                      </div>
                      <div className=" d-flex justify-content-center align-item-center">
                        <div className="next-img-div my-auto">
                          <img
                            className="next2"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_company/values/next-icon.png"
                            }
                          />
                        </div>
                        <div className="my-auto ms-4">
                          <p className="key-name">Innovation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Modal
              show={show1}
              onHide={handleClose1}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Quality</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/favorites.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show2}
              onHide={handleClose2}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Excellence</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/excellence.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show3}
              onHide={handleClose3}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Accountability</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/list.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show4}
              onHide={handleClose4}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Teamwork</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/teamwork.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show5}
              onHide={handleClose5}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Integrity</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/integration.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show6}
              onHide={handleClose6}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Customer Focus</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/customer-target.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={show7}
              onHide={handleClose7}
              centered
              className="keyvalues-modal-sec"
            >
              <Modal.Header closeButton></Modal.Header>

              <Modal.Body>
                <h4 className="mod-tt">Key Values</h4>
                <p className="mod-sb-tt">Innovation</p>

                <div className="circle-div">
                  <img
                    className="circle-inner"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our_company/values/innovation.png"
                    }
                  />
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </section>
    </>
  );
};

export default Values;
