import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavItem } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

// offcanvas lottie call
import Tablet from "../../src/animation/offcanvas-tablet/Tablet";

// sms lottie call
import Sms from "../../src/animation/sms/Sms";

// contact page call
// import Contact from "../../src/contact/Contact";

library.add(fas);

const Header = () => {
  const [scrolltopdata, setscrolltopdata] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("scrolled");
      }
    });
  }, []);

  return (
    <>
      <section className="main-header">
        <div className="top-bg"></div>

        <div className={`header-bg ${scrolltopdata}`}>
          <div className="container-fluid p-0">
            {/* sub header section start */}
            <div className="container">
              <div className="sub-header">
                <div className="sub-header-content">
                  <div className="row me-0 ms-0">
                    <div className="col-8">
                      <div className="row">
                        <div className="col-xxl-3 col-md-3 col-lg-3 col-6  ">
                          <div>
                            <p> October 13, 2023</p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-md-6 col-lg-5 col-6">
                          <div className="call-number">
                            <div className="call">
                              <FontAwesomeIcon icon="fa-solid fa-phone" />
                            </div>
                            <p> +256 312 341 100</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="header-icons">
                        <div className="icon">
                          {/* <img src={process.env.PUBLIC_URL + "assets/icons/social/facebook.png" } className="social"  alt="..." /> */}

                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/icons/social/facebook.png"
                            }
                            className="social"
                            alt="..."
                          />

                          {/* process.env.PUBLIC_URL + 'assets/images/logo/Fizo.png' */}
                        </div>
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/icons/social/linkedin.png"
                            }
                            className="social"
                            alt="..."
                          />
                        </div>
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/icons/social/twitter.png"
                            }
                            className="social"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* sub header section start */}

            {/* header-navbar start */}

            <div className="header-navbar">
              <Navbar expand="md" className="">
                <Container className="p-0">
                  <div className="">
                    <div className=" flex-twosec">
                      <Navbar.Brand href="/">
                        <div className="header-logo">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Header/cipla_logo.png"
                            }
                            className="logo"
                            alt="..."
                          />
                        </div>
                      </Navbar.Brand>

                      <Navbar.Toggle aria-controls="navbarScroll" />

                      <Navbar.Collapse id="navbarScroll">
                        <Nav
                          className="mx-auto my-2 my-md-0"
                          style={{ maxHeight: "100px" }}
                          navbarScroll
                        >
                          <Link to={"/"}>
                            <NavItem>
                              <Nav.Link className="active" href="/">
                                Home
                              </Nav.Link>
                            </NavItem>
                          </Link>

                          <NavDropdown
                            title={
                              <span>
                                Our Company
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop1">
                              <div className="dropdown-heading">
                                <p>Our Company</p>
                              </div>
                              <div className="row">
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/who-we-are/vision"}>
                                      <p>Who We Are</p>
                                    </Link>
                                  </div>
                                  <Link to={"/who-we-are/vision"}>
                                    <NavDropdown.Item href="/who-we-are/vision">
                                      Mission, Vision, values and culture
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/who-we-are/values"}>
                                    <NavDropdown.Item href="/who-we-are/values">
                                      Purpose / Core Values
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#action3">
                                      Facts & figures
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#action4">
                                      Future plan
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item>
                                      Our Policies
                                    </NavDropdown.Item>
                                  </Link>
                                  <ul className="mb-0">
                                    <li>
                                      <Link to={"/"}>
                                        <NavDropdown.Item href="/">
                                          Inclusion Diversity
                                        </NavDropdown.Item>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/our-history/qcil"}>
                                      <p>Our history</p>
                                    </Link>
                                  </div>
                                  <Link to={"/our-history/qcil"}>
                                    <NavDropdown.Item href="/our-history/qcil">
                                      QCL timeline
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our-history/foundingdirector"}>
                                    <NavDropdown.Item href="/our-history/foundingdirector">
                                      Founding Director Stories
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/our-people/board-members"}>
                                      <p>Our People</p>
                                    </Link>
                                  </div>
                                  <Link to={"/our-people/board-members"}>
                                    <NavDropdown.Item href="/our-people/board-members">
                                      Board members
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our-people/leadership-team"}>
                                    <NavDropdown.Item href="/our-people/leadership-team">
                                      Leadership team
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our-people/life-at-qcil"}>
                                    <NavDropdown.Item href="/our-people/life-at-qcil">
                                      Life at QCIL
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our-people/awards-rewards"}>
                                    <NavDropdown.Item href="/our-people/awards-rewards">
                                      Awards & Rewards
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our-people/sitetour"}>
                                    <NavDropdown.Item href="/our-people/sitetour">
                                      Site Tour
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      EHS - Environment Health Safety
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/our_company/trade"}>
                                      <p>Partnership </p>
                                    </Link>
                                  </div>
                                  <Link to={"/our_company/trade"}>
                                    <NavDropdown.Item href="/partnership/trade">
                                      Trade Partners
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_company/regulatory"}>
                                    <NavDropdown.Item href="/our_company/regulatory">
                                      Regulatory partners
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_company/licence"}>
                                    <NavDropdown.Item href="/our_company/licence">
                                      Licenses
                                    </NavDropdown.Item>
                                  </Link>
                                </div>

                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/our_company/where_to_find"}>
                                      <p>Where to found us </p>
                                    </Link>
                                  </div>
                                  <Link to={"/our_company/where_to_find"}>
                                    <NavDropdown.Item href="/where_to_find">
                                      Where to found us
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                About us
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop3">
                              <div className="dropdown-heading">
                                <Link to={"/about_us/founder"}>
                                  <p>About us</p>
                                </Link>
                              </div>
                              <div className="row">
                                <div className="col-3">
                                  <Link to={"/about_us/founder"}>
                                    <NavDropdown.Item href="/about_us/founder">
                                      Founders
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#action4">
                                      Quick Facts
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                Our Product
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop4">
                              <div className="dropdown-heading">
                                <p>Our Product</p>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <div className="sub-heading">
                                    <Link to={"/our_product/product_portfolio"}>
                                      <p>Product Portfolio</p>
                                    </Link>
                                  </div>
                                  <Link to={"/our_product/product_portfolio"}>
                                    <NavDropdown.Item href="/our_product/product_portfolio">
                                      Product Portfolio
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-4">
                                  <div className="sub-heading">
                                    <Link to={"/our_product/hiv_aids"}>
                                      <p>Therapy areas</p>
                                    </Link>
                                  </div>

                                  <NavDropdown.Item>
                                    <a className="main-anti-infective">
                                      Anti - infectives
                                      <FontAwesomeIcon
                                        icon={faAngleDown}
                                        className="anti-angle-down-icon"
                                      />
                                    </a>
                                    <ul className="mb-0">
                                      <li>
                                        <Link to={"/our_product/hiv_aids"}>
                                          <NavDropdown.Item href="/our_product/hiv_aids">
                                            HIV / AIDS
                                          </NavDropdown.Item>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={"/our_product/malaria"}>
                                          <NavDropdown.Item href="/our_product/malaria">
                                            Malaria
                                          </NavDropdown.Item>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={"/our_product/hepatitis"}>
                                          <NavDropdown.Item href="/our_product/hepatitis">
                                            Hepatitis
                                          </NavDropdown.Item>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={"/our_product/antibiotics"}>
                                          <NavDropdown.Item href="/our_product/antibiotics">
                                            Antibiotics
                                          </NavDropdown.Item>
                                        </Link>
                                      </li>
                                    </ul>
                                  </NavDropdown.Item>
                                  <Link to={"/our_product/painmanagement"}>
                                    <NavDropdown.Item href="/our_product/painmanagement">
                                      Pain Management
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/cardiodiabetic"}>
                                    <NavDropdown.Item href="/our_product/cardiodiabetic">
                                      Cardio/Diabetic
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/gastro"}>
                                    <NavDropdown.Item href="/our_product/gastro">
                                      Gastro
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/respiratory"}>
                                    <NavDropdown.Item href="/our_product/respiratory">
                                      Respiratory
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/derma"}>
                                    <NavDropdown.Item href="/our_product/derma">
                                      Derma
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/othermedicines"}>
                                    <NavDropdown.Item href="/our_product/othermedicines">
                                      Other Medicines
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-4">
                                  <div className="sub-heading">
                                    <Link to={"/our_product/accreditations"}>
                                      <p>Quality</p>
                                    </Link>
                                  </div>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#action1">
                                      Pharmacovigilance
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#action2">
                                      Quality system
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/our_product/accreditations"}>
                                    <NavDropdown.Item href="/our_product/accreditations">
                                      Accreditations
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                Our Impact
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop3">
                              <div className="dropdown-heading">
                                <Link to={"/our_impact/ehs_impact"}>
                                  <p>Our Impact</p>
                                </Link>
                              </div>
                              <Link to={"/our_impact/ehs_impact"}>
                                <NavDropdown.Item href="/our_impact/ehs_impact">
                                  EHS - Environment Health and Sustainability
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/our_impact/access"}>
                                <NavDropdown.Item href="/our_impact/access">
                                  Access to medicine
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/our_impact/ethics"}>
                                <NavDropdown.Item href="/our_impact/ethics">
                                  Ethics and compliance
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/our_impact/responsible"}>
                                <NavDropdown.Item href="/our_impact/responsible">
                                  Responsible manufacturing
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/our_impact/environmental"}>
                                <NavDropdown.Item href="/our_impact/environmental">
                                  Environmental Sustainability
                                </NavDropdown.Item>
                              </Link>
                              {/* <Link to={"/our_impact/supplier"}>
                          <NavDropdown.Item href="/our_impact/supplier">
                            Supplier information
                          </NavDropdown.Item>
                          </Link> */}
                              <Link to={"/our_impact/corporate"}>
                                <NavDropdown.Item href="/our_impact/corporate">
                                  Corporate Social responsibility
                                </NavDropdown.Item>
                              </Link>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                Invertor Relations
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop3">
                              <div className="dropdown-heading">
                                <Link
                                  to={"/investor_relations/financialreports"}
                                >
                                  <p>Invertor Relations</p>
                                </Link>
                              </div>
                              <Link to={"/"}>
                                <NavDropdown.Item href="#action1">
                                  'Why invest' pitch
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/"}>
                                <NavDropdown.Item href="#action2">
                                  Share Price Trend
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/financialreports"}>
                                <NavDropdown.Item href="/investor_relations/financialreports">
                                  Financial reports
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/annualreports"}>
                                <NavDropdown.Item href="/investor_relations/annualreports">
                                  Annual reports
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/announcement"}>
                                <NavDropdown.Item href="/investor_relations/announcement">
                                  Announcements/Notices
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/performance"}>
                                <NavDropdown.Item href="/investor_relations/performance">
                                  Performance
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/governance"}>
                                <NavDropdown.Item href="/investor_relations/governance">
                                  Corporate governance
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/"}>
                                <NavDropdown.Item href="#action7">
                                  Board committees / charters / policies
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/"}>
                                <NavDropdown.Item href="#action8">
                                  Shareholder services / engagements
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/"}>
                                <NavDropdown.Item href="#action9">
                                  Ïnvestor Relations contact
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/investor_relations/f&q"}>
                                <NavDropdown.Item href="/investor_relations/f&q">
                                  FAQ
                                </NavDropdown.Item>
                              </Link>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                Newsroom
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop5">
                              <div className="dropdown-heading">
                                <p>Newsroom</p>
                              </div>
                              <div className="row">
                                <div className="col-2">
                                  <div className="sub-heading">
                                    <Link to={"/newsroom/news"}>
                                      <p>News</p>
                                    </Link>
                                  </div>
                                  <Link to={"/newsroom/news"}>
                                    <NavDropdown.Item href="/newsroom/news">
                                      News
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-4">
                                  <div className="sub-heading">
                                    <p>Bullet-ins / newsletters</p>
                                  </div>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      Bullet-ins / newsletters
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/newsroom/library"}>
                                      <p>Media library</p>
                                    </Link>
                                  </div>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      QCIL logos
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      Photo gallery
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      Videos
                                    </NavDropdown.Item>
                                  </Link>
                                  <Link to={"/"}>
                                    <NavDropdown.Item href="#">
                                      Podcasts
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                                <div className="col-3">
                                  <div className="sub-heading">
                                    <Link to={"/newsroom/mediacontacts"}>
                                      <p>Media contact </p>
                                    </Link>
                                  </div>
                                  <Link to={"/newsroom/mediacontacts"}>
                                    <NavDropdown.Item href="/newsroom/mediacontacts">
                                      Media contact
                                    </NavDropdown.Item>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </NavDropdown>

                          <NavDropdown
                            title={
                              <span>
                                Careers
                                <FontAwesomeIcon
                                  icon={faAngleDown}
                                  className="angle-down-icon"
                                />
                              </span>
                            }
                            id="navbarScrollingDropdown"
                          >
                            <div className="dropdown-content drop3">
                              <div className="dropdown-heading">
                                <Link to={"/careers/career-opportunity"}>
                                  <p>Join Us</p>
                                </Link>
                              </div>
                              <Link to={"/careers/career-opportunity"}>
                                <NavDropdown.Item href="/careers/career-opportunity">
                                  Career opportunities
                                </NavDropdown.Item>
                              </Link>
                              <Link to={"/careers/hr_form"}>
                                <NavDropdown.Item href="/careers/hr_form">
                                  HR - Form
                                </NavDropdown.Item>
                              </Link>
                            </div>
                          </NavDropdown>
                        </Nav>

                        <Form className="">
                          <Button
                            variant="outline-light"
                            class="btn "
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-bars" />
                          </Button>
                        </Form>
                      </Navbar.Collapse>
                    </div>
                  </div>
                </Container>
              </Navbar>
            </div>

            {/* header-navbar start */}
          </div>
        </div>

        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="bg-img">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/image/Header/offcanvas_bg.png"
              }
              className="Offcanvas-bg_img"
              alt="..."
            />
          </div>
          <div className="row me-0 ms-0">
            <div className="col-6 p-0">
              <div className="row me-0 ms-0">
                <div className="col-12 p-0">
                  <div className="first-empty"></div>
                </div>
              </div>
            </div>

            <div className="col-6 p-0">
              <div className="row me-0 ms-0">
              <div className="col-2 p-0">
                  <div className="second-bg"></div>
                </div>
                <div className="col-10 p-0">
                  <div className="last-content">
                    <div class="offcanvas-header">
                      <Button data-bs-dismiss="offcanvas" aria-label="Close">
                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                      </Button>
                    </div>

                    <Tablet />
                    <div class="offcanvas-body">
                      <div className="footer-link">
                        <ul>
                          <li data-bs-dismiss="offcanvas">
                            <Link to={"/contact"}>
                              <p>Contact Us</p>
                            </Link>
                          </li>
                          <div className="hr"></div>
                          <li data-bs-dismiss="offcanvas">
                            <Link to={"/privacypolicy"}>
                              <p>Privacy Policy</p>
                            </Link>
                          </li>
                          <div className="hr"></div>
                          <li data-bs-dismiss="offcanvas">
                            <Link to={"/"}>
                              <p> Terms & Conditions</p>
                            </Link>
                          </li>
                          <div className="hr"></div>
                          <li data-bs-dismiss="offcanvas">
                            <Link to={"/"}>
                              <p>Social Media </p>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sms-lottie">
        <div className="lottie">
          {" "}
          <Sms />
        </div>
      </section>
    </>
  );
};

export default Header;
