import {React, useEffect} from "react";
import "./Announcement.css";
import Button from "react-bootstrap/Button";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

function Announcement() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="announcement">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="annual-content">
                <div className="main-content-holder">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-12 order-md-2 order-lg-1">
                      <div className="main-slider-section">
                        <div className="announcement-heading">
                          <h1 className="title">Announcements/Notices</h1>
                        </div>
                        <div className="slider-content">
                          <Swiper
                            className="announcement-swiper"
                            navigation={{
                              nextE1: ".swiper-button-next", // CSS class for the next button
                              prevE1: ".swiper-button-prev", // CSS class for the previous button
                            }}
                            pagination={{ clickable: false }}
                            //   autoplay={{ delay: 3000 }}
                            loop={false}
                            slidesPerView={1} // Display one slide at a time
                          >
                            <SwiperSlide>
                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      QCIL 2023 Post AGM Notice{" "}
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                      className="on-hover"
                                      id="btn-size" target="_blank"
                                       href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/CiplaQCIL-Post-AGM-Notice-2023.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      QCIL 2023 AGM Notice
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                     className="on-hover"
                                      id="btn-size" target="_blank"
                                      href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/CiplaQCIL-AGM-Notice-2023.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      FAQ On The Proposed Sale Of Shares By
                                      Meditab Holding Limited And QCIL (EU) Ltd
                                      To Africa Capitalworks SSA{" "}
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                      className="on-hover"
                                      id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2023/03/FAQ-on-the-Proposed-Sale-of-Shares-by-Meditab-Holding-Limited-and-Cipla-EU-Ltd-to-Africa-Capitalworks-SSA.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      Cautionary Announcement
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                     className="on-hover"
                                      id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2023/03/Cautionary-Announcement.15.03.23.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>

                            <SwiperSlide>
                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      QCIL 2023 Post AGM Notice{" "}
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                    className="on-hover"
                                      id="btn-size" target="_blank"
                                       href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/CiplaQCIL-Post-AGM-Notice-2023.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      QCIL 2023 AGM Notice
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                     className="on-hover"
                                      id="btn-size" target="_blank"
                                      href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/CiplaQCIL-AGM-Notice-2023.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      {" "}
                                      FAQ On The Proposed Sale Of Shares By
                                      Meditab Holding Limited And QCIL (EU) Ltd
                                      To Africa Capitalworks SSA{" "}
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                     className="on-hover"
                                      id="btn-size" target="_blank"  href="https://ciplaqcil.co.ug/wp-content/uploads/2023/03/FAQ-on-the-Proposed-Sale-of-Shares-by-Meditab-Holding-Limited-and-Cipla-EU-Ltd-to-Africa-Capitalworks-SSA.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>

                              <div className="main-more-info-box">
                                <div className="more-info-box">
                                  <div className="text-holder">
                                    <p className="title">
                                      Cautionary Announcement
                                    </p>
                                  </div>
                                  <div className="purple-read-more">
                                    <Button
                                     className="on-hover"
                                      id="btn-size" target="_blank" href="https://ciplaqcil.co.ug/wp-content/uploads/2023/03/Cautionary-Announcement.15.03.23.pdf"
                                    >
                                      More info
                                    </Button>{" "}
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-6 order-md-1 order-lg-2">
                      <div className="image-holder">
                        <img
                          className="announcement-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/investor_relations/announcement/announcement.png"
                          }
                          alt="announcement-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Announcement;
