import {React,useEffect} from "react";
import "./Governance.css";
import Button from "react-bootstrap/Button";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

function Governance() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="Governance">
        <div className="Governance-bg">
          <img
            className="Governance-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/investor_relations/governance/governance_bg.png"
            }
            alt="Governance-bg-img"
          />
        </div>
        <div className="container">
          <div className="main-content-holder">
            <div className="report-heading">
              <h1 className="title">Corporate</h1>
              <h2>Governance</h2>
            </div>

            <div className="performance-cards-content">
            <div className="container">
              <div className="row justify-content-center me-0 ms-0">
                <div className="col-xl-9 col-lg-10 col-md-11">
                  <div className="cards-holder">
                    <Swiper
                      className="Governance-swiper"
                      navigation={{
                        nextE1: ".swiper-button-next", // CSS class for the next button
                        prevE1: ".swiper-button-prev", // CSS class for the previous button
                      }}
                      pagination={{ clickable: true }}
                      //   autoplay={{ delay: 3000 }}
                      loop={true}
                      slidesPerView={3} // Display one slide at a time
                    >
                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_1.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://ciplaqcil.co.ug/wp-content/uploads/2021/12/CODE-OF-CONDUCT-CiplaQCIL-optimised.pdf">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_2.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://ciplaqcil.co.ug/wp-content/uploads/2021/12/CODE-OF-CONDUCT-CiplaQCIL-optimised.pdf">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_3.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://station.cipla.co.za/app/#/s/ncSv0v8">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_1.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://ciplaqcil.co.ug/wp-content/uploads/2021/12/CODE-OF-CONDUCT-CiplaQCIL-optimised.pdf">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_2.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://ciplaqcil.co.ug/wp-content/uploads/2021/12/CODE-OF-CONDUCT-CiplaQCIL-optimised.pdf">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="slider-img-holder">
                          <img
                            className="Governance-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/investor_relations/governance/governance_slider_img_3.png"
                            }
                            alt="Governance-img"
                          />
                          <div className="Download" id="governance-download-btn">
                            <Button className="Download-btn titte" id="no-hover" target="_Black" href="https://station.cipla.co.za/app/#/s/ncSv0v8">
                              Download
                            </Button>{" "}
                          </div>
                          <div className="overlay"></div>
                          <div className="hr"></div>
                        </div>
                      </SwiperSlide>


                    
                     
                    </Swiper>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Governance;
