import { React, useRef, useEffect } from "react";
import "./Vision.css";
import Card from "react-bootstrap/Card";
// lottie call
import Vissionlottie from "../../../../src/animation/vission_lottie/Vissionlottie";
import Messionlottie from "../../../../src/animation/mession_lottie/Messionlottie";
import Culturelottie from "../../../../src/animation/culture_lottie/Culturelottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Vision() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Who We Are</h1>
        </div>
      </section>

      <section className="main">
        <div className="container-fluid p-0">
          <section className="vision">
            <div className="vision-content">
              <div className="container">
                {/* lifelong section start */}
                <div className="lifelong">
                  <div className="row justify-content-center">
                    <div className="col-xxl-5 col-lg-5 col-md-6 col-12">
                      <div className="vision-text-holder">
                        <div className="text-heading">
                          <h1 className="title">Lifelong care by QCIL</h1>
                          <p>
                            At Quality Chemical Industries Limited (QCIL), we
                            are not mere manufacturers of medication. What we
                            make are memories. Memories that may not have been
                            there to be remembered, had a life not been lived.
                            We make people better and we save lives. <br />
                            <br /> That’s why we don’t have a strategy, we have
                            a cause. We don’t have a vision statement, we have
                            vision.
                          </p>
                          <span>
                            It's what we call Human Care{" "}
                            <FontAwesomeIcon className="arrow" icon="fa-solid fa-angle-right" />
                            <vr class="border-dashed"></vr>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-5 col-lg-5 col-md-6 col-12">
                      <div className="vision-img-bg">
                        {/* <div className="bg-img-holder">
                          <img
                            className="vision-bg-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/Home/Worth/Worth _img_bg.png"
                            }
                            alt="vision-bg-img"
                          />
                        </div> */}
                        <div className="img-holder">
                          <img
                            className="vision-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our-company/who-we-are/mission/Whoweare.png"
                            }
                            alt="who-we-are-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* lifelong section end */}

                {/* vdo section start */}
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="vdo-box">
                      <div className="vdo">
                        {/* <div className="who-we-are-vdo"> */}
                        <video
                          autoPlay
                          muted
                          loop
                          className="who-we-are-vdo"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/vedio/Our_Company/Who_we_are/Human+Care+-+Uganda+GiveFastLink.mp4"
                          }
                          alt="who-we-are"
                        />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* vdo section end */}
              </div>

              {/* vesion mission culture section start */}
              <div className="vision-mission-culture">
                <div className="vision-mission-culture-bg-img">
                  <img
                    className="vision-mission-bg-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/image/our-company/who-we-are/mission/vision_mission_bg.png"
                    }
                    alt="vision-mission-bg-img"
                  />
                </div>
                  <div className="vision-mission-cards">
                <div className="container">
                    <div className="row justify-content-center me-0 ms-0">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="vision-card">
                          <Card>
                            <div className="lottie">
                              <Vissionlottie />
                            </div>
                            <Card.Body>
                              <Card.Title className="title">Vision</Card.Title>
                              <Card.Text>
                                To become a centre of excellence in <br /> the
                                manufacturing of quality, <br /> affordable
                                medicines.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4">
                        <div className="vision-card">
                          <Card>
                            <div className="lottie">
                              {" "}
                              <Messionlottie />
                            </div>
                            <Card.Body>
                              <Card.Title>Mission</Card.Title>
                              <Card.Text>
                                To provide affordable and <br /> efficacious
                                medicines in a <br /> sustainable way, to
                                improve the <br /> quality of life.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                      <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4">
                        <div className="vision-card">
                          <Card>
                            <div className="lottie">
                              {" "}
                              <Culturelottie />
                            </div>
                            <Card.Body>
                              <Card.Title>Culture</Card.Title>
                              <Card.Text>
                                We are an equal-opportunity <br /> employer and
                                do not discriminate <br /> on gender, age,
                                disability, ethnicity, <br /> religious grounds,
                                or nationality.
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* vesion mission culture section end */}
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default Vision;
