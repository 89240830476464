import { React, useEffect } from "react";
import "./News.css";
import "swiper/swiper-bundle.min.css";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
SwiperCore.use([Navigation, Autoplay]);

function News() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Newsroom</h1>
        </div>
      </section>

      <section className="news">
        {/* <div className="news-bg">
          <img
            className="news-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/newsroom/news/new_bg.png"
            }
            alt="news-bg-img"
          />
        </div> */}
        <div className="news-card">
        <div className="container">
        <div className="news-heading">
            <h1 className="title">
              News
            </h1>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="new-slider-content">
                <Swiper
                  className="news-swiper"
                  navigation={{
                    nextE1: ".swiper-button-next", // CSS class for the next button
                    prevE1: ".swiper-button-prev", // CSS class for the previous button
                  }}
                  pagination={{ clickable: true }}
                  //   autoplay={{ delay: 3000 }}
                  loop={false}
                  slidesPerView={2} // Display one slide at a time
                  breakpoints={{
                    768: {
                      slidesPerView: 1, // Display two slides at a time for smaller views (e.g., tablets)
                    },
                    992: {
                      slidesPerView: 2, // Display two slides at a time for smaller views (e.g., tablets)
                    },
                  }}
                >
                  <SwiperSlide>
                  <Link to={"/newsroom/newsinner"}>
                    <div className="main-img" id="gap">
                      <img
                        className="swiper-main-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/Home/Media/main_img_1.png"
                        }
                        alt="..."
                      />
                      <div className="overlay">
                        <div className=" date-holder">
                          <p>19 Oct 2023</p>
                        </div>
                        <div className="main-text-holder">
                          <div className="text-holder">
                            <h1 className="title">
                              {" "}
                              Cautionary <br /> Announcement
                            </h1>
                            <p>
                              On 14 March 2023, the Board of Directors <br />
                              of QCIL Quality Chemical Industries Limited <br />
                              (the “Company”) was advised by Africa <br />
                              Capitalworks SSA 3 (the “Offeror”)
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Link to={"/newsroom/newsinner"}>
                              <Button className="read-more">
                                <FontAwesomeIcon
                                  className="read-more-btn"
                                  icon="fa-solid fa-arrow-right"
                                />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>

                  <SwiperSlide>
                  <Link to={"/newsroom/newsinner"}>
                    <div className="main-img" id="gap">
                      <img
                        className="swiper-main-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/Home/Media/main_img_1.png"
                        }
                        alt="..."
                      />
                      <div className="overlay">
                        <div className=" date-holder">
                          <p>19 Oct 2023</p>
                        </div>
                        <div className="main-text-holder">
                          <div className="text-holder">
                            <h1 className="title">
                              {" "}
                              Cautionary <br /> Announcement
                            </h1>
                            <p>
                              On 14 March 2023, the Board of Directors <br />
                              of QCIL Quality Chemical Industries Limited <br />
                              (the “Company”) was advised by Africa <br />
                              Capitalworks SSA 3 (the “Offeror”)
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Link to={"/newsroom/newsinner"}>
                              <Button className="read-more">
                                <FontAwesomeIcon
                                  className="read-more-btn"
                                  icon="fa-solid fa-arrow-right"
                                />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>

                  <SwiperSlide>
                  <Link to={"/newsroom/newsinner"}>
                    <div className="main-img" id="gap">
                      <img
                        className="swiper-main-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/image/Home/Media/main_img_1.png"
                        }
                        alt="..."
                      />
                      <div className="overlay">
                        <div className=" date-holder">
                          <p>19 Oct 2023</p>
                        </div>
                        <div className="main-text-holder">
                          <div className="text-holder">
                            <h1 className="title">
                              {" "}
                              Cautionary <br /> Announcement
                            </h1>
                            <p>
                              On 14 March 2023, the Board of Directors <br />
                              of QCIL Quality Chemical Industries Limited <br />
                              (the “Company”) was advised by Africa <br />
                              Capitalworks SSA 3 (the “Offeror”)
                            </p>
                          </div>
                          <div className="btn-holder">
                            <Link to={"/newsroom/newsinner"}>
                              <Button className="read-more">
                                <FontAwesomeIcon
                                  className="read-more-btn"
                                  icon="fa-solid fa-arrow-right"
                                />
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    </Link>
                  </SwiperSlide>

                 
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default News;
