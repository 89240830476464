import { React, useEffect } from "react";
import "./Banner.css";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Autoplay]);

function Banner() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  

  return (
    <section className="main">
      <div className="container-fluid p-0">
        <section className="home-banner" id="banner-body">
          <div className="banner">

            <Swiper
              className="banner-swiper"
              navigation={{
              nextE1: ".swiper-button-next", // CSS class for the next button
              prevE1: ".swiper-button-prev", // CSS class for the previous button
              }}
              pagination={{ clickable: false }}
              loop={false}
              slidesPerView={1}>


              <SwiperSlide>
                <img src={ process.env.PUBLIC_URL + "assets/image/Home/Banner/banner_img_1.png" } className="img-fluid" alt="..." />
                {/* <video className="video-set" autoplay muted  loop playsinline> <source src="" autoplay type="video/mp4" /></video> */}
              </SwiperSlide>


              <SwiperSlide>
                <img src={ process.env.PUBLIC_URL + "assets/image/Home/Banner/banner_img_2.png"} className="img-fluid" alt="..."/>
                {/* <video className="video-set" autoplay muted  loop playsinline>   <source src="" autoplay type="video/mp4" /> </video> */}
              </SwiperSlide>


              <SwiperSlide>
                <img  src={process.env.PUBLIC_URL + "assets/image/Home/Banner/banner_img_3.png"} className="img-fluid" alt="..."/>
                {/* <video className="video-set" autoplay muted  loop playsinline> <source src="" autoplay type="video/mp4" />  </video> */}
              </SwiperSlide>


            </Swiper>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Banner;
