import React, { useState, useEffect } from "react";
import "./NewsInner.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
library.add(fas);

function NewsInner() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const [selectedDate, setSelectedDate] = useState(new Date());
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>News</h1>
        </div>
      </section>

      <section className="NewsInner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 p-0">
              <Link to={"/newsroom/news"}>
                <div className="back">
                  <p className="title">Go Back</p>
                </div>
              </Link>
              <div className="NewsInner-content-holder">
                <div className="newsinner-heading-content">
                  <h1 className="title">Cautionary Announcement</h1>
                </div>
                <div className="date-and-share-holder">
                  <div className="date-holder">
                    <FontAwesomeIcon
                      className="calender"
                      icon="fa-solid fa-calendar-days"
                    />{" "}
                    <DatePicker
                      className="title date-font"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MMMM d, yyyy"
                    />
                  </div>
                  <div className="vr"></div>
                  <div className="share">
                    <FontAwesomeIcon
                      className="share-icon"
                      icon="fa-solid fa-share-nodes"
                    />
                  </div>
                </div>
                <div className="update-text">
                  <p className="title">Investor Updates</p>
                </div>
                <div className="inner-div">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-12">
                      <div className="text-content-holder">
                        <p className="title">
                          <b>
                            On 14 March 2023, the Board of Directors of QCIL
                            Quality Chemical Industries Limited (the “Company”)
                            was advised by Africa Capitalworks SSA 3 (the
                            “Offeror”) of its intention to acquire 51.18% of the
                            issued ordinary shares of the Company (“Shares”), as
                            to 51.05% from Meditab Holdings Limited and 0.13%
                            from QCIL (EU) Limited (the “Transaction”). The
                            sellers are both wholly owned by QCIL Limited.
                          </b>
                        </p>

                        <p>
                          The Board has been informed that the Offeror has
                          applied to the Capital Markets Authority for approval
                          of this transaction in line with the Capital Markets
                          (Takeovers and Mergers) Regulations, 2012. This
                          transaction, if approved, will lead to a change in the
                          Company’s shareholding structure.
                        </p>

                        <p>
                          Shareholders and the public are hereby notified of
                          this development and are advised to exercise caution
                          when dealing in the Shares of the Company.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                      <div className="img-holder">
                        <img
                          className="news-inner-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/image/newsroom/news_inner/news_inner.png"
                          }
                          alt="news-inner-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="latest-company">
                    <div className="company-heading-holder">
                      <h2 className="title">Latest Company</h2>
                      <h1>News</h1>
                    </div>
                    {/* latest-company-section start */}
                    <div className="company-info">
                      <div className="row justify-content-start">
                        <div className="col-lg-6 col-md-12">
                          <div className="card-holder">
                            <div className="row">
                              <div className="col-4">
                                <div className="img-holder">
                                  <img
                                    className="company-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/newsroom/news_inner/company/comany_member1.png"
                                    }
                                    alt="company-img"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="text-holder">
                                  <h1 className="title">
                                    QCIL Registers <br /> Growth Amid Tough
                                    Times
                                  </h1>
                                </div>
                                <div className="date-holder">
                                  <FontAwesomeIcon
                                    className="calender"
                                    icon="fa-solid fa-calendar-days"
                                  />{" "}
                                  <DatePicker
                                    className="title date-font"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="MMMM d, yyyy"
                                  />
                                </div>
                                <div className="btn-holder">
                                  <Button className="trans-read-more">
                                    <FontAwesomeIcon
                                      className="trans-read-more-btn"
                                      icon="fa-solid fa-arrow-right"
                                    />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="card-holder">
                            <div className="row">
                              <div className="col-4">
                                <div className="img-holder">
                                  <img
                                    className="company-img"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/image/newsroom/news_inner/company/comany_member1.png"
                                    }
                                    alt="company-img"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="text-holder">
                                  <h1 className="title">
                                    QCIL Registers <br /> Growth Amid Tough
                                    Times
                                  </h1>
                                </div>
                                <div className="date-holder">
                                  <FontAwesomeIcon
                                    className="calender"
                                    icon="fa-solid fa-calendar-days"
                                  />{" "}
                                  <DatePicker
                                    className="title"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="MMMM d, yyyy"
                                  />
                                </div>
                                <div className="btn-holder">
                                  <Button className="trans-read-more">
                                    <FontAwesomeIcon
                                      className="trans-read-more-btn"
                                      icon="fa-solid fa-arrow-right"
                                    />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* latest-company-section end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsInner;
