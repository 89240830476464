import { useState, useEffect } from "react";
import "../stepform.css";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

function Jobdetailsform() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  
  const [item, setItem] = useState({ kindOfStand: "", another: "another" });

  const { kindOfStand } = item;

  const handleChange = (e) => {
    e.persist();
    console.log(e.target.value);

    setItem((prevState) => ({
      ...prevState,
      kindOfStand: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`${kindOfStand}`);
  };

  return (
    <>
      <section className="formm-sec">
        <div className="container">
          <Form>
            <Row>
              <Form.Group as={Col} md="6" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">
                      What is your preferred field of work?{" "}
                    </label>
                  </div>

                  <div className="">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Your Preferred Field of Work"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="6" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Expected Salary </label>
                  </div>

                  <div className="">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Expected Salary"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Expected Benefits</label>
                  </div>

                  <div className="">
                    <textarea
                      rows={1}
                      required
                      type="text"
                      placeholder="Enter Expected Salary"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className=" gender-flex">
                  <div className="type">
                    <label className="form-label">
                      Would you accept employment for less than a year?{" "}
                    </label>
                  </div>
                  <div className="d-flex">
                    <div className="type">
                      <Form.Check
                        value="Yes"
                        type="radio"
                        aria-label="radio 1"
                        label="Yes"
                        onChange={handleChange}
                        checked={kindOfStand === "Yes"}
                      />
                    </div>

                    <div className="type">
                      <Form.Check
                        value="No"
                        type="radio"
                        aria-label="radio 2"
                        label="No"
                        onChange={handleChange}
                        checked={kindOfStand === "No"}
                      />
                    </div>
                  </div>
                </div>
              </Form.Group>

              
              <Form.Group as={Col} md="12" className="form-group">
                <div className="gender-flex">
                  <div className="">
                    <label className="form-label">Expected Benefits</label>
                  </div>

                  <div className="">
                    <textarea
                      rows={1}
                      required
                      type="text"
                      placeholder="Have you previously applied for employment with QCIL? Were you invited for an interview? If so, when?"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group as={Col} md="12" className='form-group'>
                <div className='d-flex space-between gender-flex'>
                  <div className='type'>
                    <label className='form-label'>Are any of your relatives employed by, or do you know anybody in Quality Chemical industries Limited? <br /> <span>(If answer is “Yes”, give the following information)</span> </label>
                  </div>


                  <div className='type'>
                    <Form.Check value="Yes" type="radio" aria-label="radio 3" label="Yes"
                      onChange={handleChange}
                      checked={kindOfStand === "Yes"}
                    />
                  </div>

                  <div className='type'>
                    <Form.Check
                      value="No"
                      type="radio"
                      aria-label="radio 4"
                      label="No"
                      onChange={handleChange}
                      checked={kindOfStand === "No"}
                    />
                  </div>

                </div>
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Relationship"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Position / Role in QICL"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Relationship"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Position / Role in QICL"
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="form-group">
                <div className="d-flex gender-flex end">
                  <div className="type">
                    <label className="form-label" id="underline">
                      Add More
                    </label>
                  </div>
                </div>
              </Form.Group>

           
            </Row>
          </Form>
        </div>
      </section>
    </>
  );
};

export default Jobdetailsform;
