import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Personalform from "./personal_details_tab/Personalform";
import Academicstepform from "./academic_tab/Academicstepform";
import Jobdetailsform from "./job_details_tab/Jobdetailsform";
import Tabform from "./other_tab/Tabform";
import Successlottie from "../animation/success_lottie/Successlottie";
import "./stepform.css";

function Stepform2(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleSubmit = () => {
    // Logic to handle form submission
    setShowModal(true);

    // Hide the modal after 5 seconds
    // setTimeout(() => {
    //   setShowModal(false);
    // }, 1000);
  };

  const [activeTab, setActiveTab] = useState("Personal Details");

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const handleAcademicNext = () => {
    // Logic to handle moving to the next tab after Academicstepform
    setActiveTab("Job Details");
  };

  const handlepersonalNext = () => {
    // Logic to handle moving to the prev tab after PersonalDetails
    setActiveTab("Personal Details");
  };

  const handleNext = () => {
    switch (activeTab) {
      case "Personal Details":
        // Additional logic for Personal Details tab
        // For example, validate the form fields
        // If everything is okay, move to the next tab
        setActiveTab("Academic & Skill Sets");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      case "Academic & Skill Sets":
        // Additional logic for Academic & Skill Sets tab
        // If everything is okay, move to the next tab
        // If everything is okay, move to the next tab
        setActiveTab("Job Details");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      case "Job Details":
        // Additional logic for Job Details tab
        // If everything is okay, move to the next tab
        // If everything is okay, move to the next tab
        setActiveTab("Other");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    // Logic to handle moving to the previous tab
    // Update state accordingly
    switch (activeTab) {
      case "Academic & Skill Sets":
        setActiveTab("Personal Details");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      case "Job Details":
        setActiveTab("Academic & Skill Sets");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      case "Other":
        setActiveTab("Job Details");
        window.scrollTo(0, 0); // Scroll to the top of the page
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title title">
          <h1 id="career-heading">QUALITY CHEMICAL INDUSTRIES LIMITED</h1>
        </div>
      </section>

      <section className="step-form-sec ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="form-b-div">
                <h6 className="form-b-title">
                  FORM B : Graduate Trainee - Personal Data Form
                </h6>
                <div className="sub-title">
                  <Link to={"/careers/career-opportunity"}>
                    <p className="sub-t title">
                      <FontAwesomeIcon
                        className="back"
                        icon="fa-solid fa-angle-left"
                      />{" "}
                      Back
                    </p>
                  </Link>
                  <p className="sub-t">
                    INSTRUCTIONS : Please read and answer each question clearly
                    and completely, as per the instructions. Type or print in
                    ink.{" "}
                  </p>
                </div>
              </div>

              <div className="step-all-secc step-buttons-1">
                {/* <MultiStep steps={steps} /> */}

                <Tabs
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                  id="justify-tab-example"
                  className="career-step"
                  fill
                >
                  <Tab
                    eventKey="Personal Details"
                    title={
                      <span
                        style={{
                          color:
                            activeTab === "Academic & Skill Sets" ||
                            activeTab === "Job Details" ||
                            activeTab === "Other"
                              ? "#562278"
                              : "",
                          fontWeight:
                            activeTab === "Academic & Skill Sets" ||
                            activeTab === "Job Details" ||
                            activeTab === "Other"
                              ? "600"
                              : "600",
                        }}
                      >
                        Personal Details
                      </span>
                    }
                  >
                    <Personalform />
                    <div className="form-navigation-buttons">
                      <button
                        className=" title"
                        id="nav-submit-btn"
                        onClick={handleNext}
                        disabled={activeTab === "Other"}
                      >
                        Next
                      </button>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="Academic & Skill Sets"
                    title={
                      <span
                        style={{
                          color:
                            activeTab === "Job Details" || activeTab === "Other"
                              ? "#562278"
                              : "",
                          fontWeight:
                            activeTab === "Job Details" || activeTab === "Other"
                              ? "600"
                              : "600",
                        }}
                      >
                        Academic & Skill Sets
                      </span>
                    }
                  >
                    <Academicstepform
                      onNext={handleAcademicNext}
                      onPrev={handlepersonalNext}
                    />
                    {/* <div className="form-navigation-buttons">
                  <button className=" title" id="nav-back-btn" onClick={handleBack}  disabled={activeTab === "Personal Details"}>
                    Back
                  </button>
                  <button  className=" title" id="nav-next-btn" onClick={handleNext} disabled={activeTab === "Other"}>
                    Next
                  </button>
                </div> */}
                  </Tab>

                  <Tab
                    eventKey="Job Details"
                    title={
                      <span
                        style={{
                          color: activeTab === "Other" ? "#562278" : "",
                          fontWeight: activeTab === "Other" ? "600" : "600",
                        }}
                      >
                        Job Details
                      </span>
                    }
                  >
                    <Jobdetailsform />
                    <div className="form-navigation-buttons">
                      <button
                        className=" title"
                        id="nav-back-btn"
                        onClick={handleBack}
                        disabled={activeTab === "Personal Details"}
                      >
                        Back
                      </button>
                      <button
                        className=" title"
                        id="nav-next-btn"
                        onClick={handleNext}
                        disabled={activeTab === "Other"}
                      >
                        Next
                      </button>
                    </div>
                  </Tab>

                  <Tab eventKey="Other" title={<span>Other</span>}>
                    <Tabform />
                    <div className="form-navigation-buttons">
                      <button
                        className=" title"
                        id="nav-submit-btn"
                        onClick={handleSubmit}

                        // disabled={activeTab === "Other"}
                      >
                        Submit
                      </button>
                    </div>
                  </Tab>
                </Tabs>

                <Modal
                  className="success-modal"
                  {...props}
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Body>
                    <Successlottie />

                    <p className="title">
                      Your Application has been Submitted Successfully!
                      <br />
                      <span className="title">Have a Great Time!</span>
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stepform2;
