import {React, useEffect} from "react";
import "../../product_style/Product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Antibiotics() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Therapy Areas</h1>
        </div>
      </section>

      <section className="Hivaids">
        <div className="main-holder">
          <div className="heading">
            <p className="title">Anti-infectives</p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="main-content-holder">
                  <div className="row justify-content-center">
                    <div className="col-md-7 ps-0">
                      <div className="text-content-holder">
                        <p>
                        Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. <br /> <br /> Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business. Placed Dummy Content here. Need content from business.
                        </p>
                        <div className="main-img-div">
                          <div className="row justify-content-end">
                            <div className="col-md-5">
                              <div className="img-holder">
                                <img
                                  className="infectives-img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/image/our_product/anti-infectives/hiv_img.png"
                                  }
                                  alt="infectives-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="sub-heading-holder">
                        <div className="sub-heading">
                          <p>Antibiotics</p>
                        </div>
                        <h1 className="title" id="right">
                        Placed Dummy Content <br /> here. Need content from  <br />business.
                        </h1>
                        <vr class="border-dashed-2"></vr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Antibiotics;
