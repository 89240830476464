import {useEffect} from "react";
import "./Library.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
library.add(fas);

// Install the required Swiper modules
SwiperCore.use([Navigation, Autoplay]);

function Library() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Newsroom</h1>
        </div>
      </section>

      <section className="Library">
        <div className="blue-bg"></div>
        <div className="container">
          <div className="main-holder">
            <div className="Library-heading">
              <h1 className="title">
                <b>Media Library</b>
              </h1>
            </div>
            <div className="content-holder">
              <Tabs
                defaultActiveKey="photogallery"
                transition={false}
                id="noanim-tab-example"
                className="library-tabs mb-3"
              >
                <Tab eventKey="qcillogos" title="QCIL Logos">
                  Tab content for QCIL Logos
                </Tab>


                {/* photo gallery-tab-start */}

               
                <Tab eventKey="photogallery" title="Photo gallery">

                  
                  <Swiper
                    className="library-swiper"
                    centeredSlides="true"
                    navigation={{
                      nextEl: ".swiper-button-next", // CSS class for the next button
                      prevEl: ".swiper-button-prev", // CSS class for the previous button
                    }}
                    pagination={{ clickable: true }}
                    //   autoplay={{ delay: 3000 }}
                    loop={true}
                    slidesPerView={1}
                    // Display one slide at a time
                  >
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="gallery-img-holder">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                              }
                              className="photo-gallery-img"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Tab>
                {/* photo gallery-tab-end */}



                {/* vdo tab start */}
                <Tab eventKey="videos" title="Videos">
                  <Swiper
                    className="library-swiper"
                    centeredSlides="true"
                    navigation={{
                      nextEl: ".swiper-button-next", // CSS class for the next button
                      prevEl: ".swiper-button-prev", // CSS class for the previous button
                    }}
                    pagination={{ clickable: true }}
                    //   autoplay={{ delay: 3000 }}
                    loop={true}
                    slidesPerView={1}
                    // Display one slide at a time
                  >
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="main-img-vdo-holder">
                            <div className="gallery-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/photos_gallary/photo_gallery_img.png"
                                }
                                className="photo-gallery-img"
                                alt="..."
                              />
                            </div>
                            <div className="play-btn-holder">
                              <FontAwesomeIcon
                                className="vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Tab>
                {/* vdo tab end */}



                {/* podcast tab start */}
                <Tab eventKey="podcasts" title="Podcasts">
                  <Swiper
                    className="library-swiper"
                    centeredSlides="true"
                    navigation={{
                      nextEl: ".swiper-button-next", // CSS class for the next button
                      prevEl: ".swiper-button-prev", // CSS class for the previous button
                    }}
                    pagination={{ clickable: true }}
                    //   autoplay={{ delay: 3000 }}
                    loop={true}
                    slidesPerView={1}
                    // Display one slide at a time
                  >
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="main-podcast-holder">
                            <div className="podcast-img-holder">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/image/newsroom/library/podcast/podcast_img.png"
                                }
                                className="podcast-img"
                                alt="podcast-img"
                              />
                            </div>
                            <div className="text-holder">
                              <p className="title">Podcast Name</p>
                            </div>
                            <div className="podcast-play-btn-holder">
                              <FontAwesomeIcon
                                className="podcast-vdo-play-btn"
                                icon="fa-solid fa-circle-play"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Tab>
                {/* podcast tab end */}


              </Tabs>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Library;
