import {React, useEffect} from "react";
import "../impact_style/our_impact.css"
import Button from "react-bootstrap/Button";

function Ethics() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Our Impact</h1>
        </div>
      </section>

      <section className="EHS-Sustainability">
        <div className="container">
          <div className="EHS-content">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-6">
                <div className="text-holder">
                  <h1 className="title">Ethics and compliance</h1>
                  <p>
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    <br />
                    <br />
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                    Placed Dummy Content here. Need content from business.
                  </p>
                  <div className="purple-read-more" >
                    <Button className="purple-read-more-btn" id="no-shadow">Read More</Button>{' '}
                    </div>
                </div>
              </div>

              
              <div className="col-lg-5 col-md-6">
                <div className="img-holder">
                  <img
                    className="EHS-img"
                    src={process.env.PUBLIC_URL + "/assets/image/our-impact/EHS/EHS.png"}
                    alt="EHS-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Ethics;
