import {React, useEffect} from "react";
import "./Wheretofind.css";

function Wheretofind() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Where to find Us</h1>
        </div>
      </section>

      <section className="Wheretifind">
        <div className="container">
          <div className="find-content">
            <div className="row justify-content-center">
              {/* <div className="col-lg-5 col-md-6">
                <div className="find-us-map">
                  <div
                    style={{
                      width: "100%",
                      height:"269px",
                      overflow: "hidden",
                      borderRadius: "13px", // Add border-radius
                    }}
                  >
                    <iframe
                      width="100%"
                      height="269px"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=kampala+(My%20Business%20Name)&amp;t=&amp;z=6&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6  ">
                <div className="text-holder">
                  <h1 className="title">Location</h1>
                  <p>
                  QCIL Quality Chemical Industries Limited Plot 1-7, 1st Ring
                    Road, Luzira Industrial Park P.O Box 34871, Kampala-Uganda
                  </p>
                </div>
              </div> */}

              <div className="col-lg-10 col-md-10">
                <div className="find-us-map">
                  <div className="map-style">
                    <iframe
                      width="100%"
                      height= "668"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=kampala+(My%20Business%20Name)&amp;t=&amp;z=6&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Wheretofind;
