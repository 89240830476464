import {React, useEffect} from "react";
import "./Accreditations.css";

function Accreditations() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Quality</h1>
        </div>
      </section>

      <section className="Accreditations">
        <div className="Accreditations-heading">
          <h1 className="title">Accreditations</h1>
        </div>
        <div className="accredition-main-containt-holder">
        <div className="blue-bg"></div>
        <div className="container">
        <div className="row justify-content-center">
            <div className=" col-lg-10 col-md-12">
              <div className="content-div">
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="main-img-text-holder-div">
                        <div className="img-holder">
                          <img
                            className="accreditations-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/image/our_product/quality/accreditions.png"
                            }
                            alt="accreditations-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
         
        </div>
      </section>
    </>
  );
}

export default Accreditations;
