import { React, useEffect } from "react";
import "./FandQ.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function FandQ() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
      <section className="section-top-heading">
        <div className="header-bg">
          <img
            className="vision-bg-img"
            src={
              process.env.PUBLIC_URL +
              "/assets/image/our-company/who-we-are/mission/header_bg.png"
            }
            alt="header-bg-img"
          />
        </div>
        <div className="heading-title">
          <h1>Investor Relations</h1>
        </div>
      </section>

      <section className="FandQ">
        <div className="blue-bg"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="f-q-content">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-12">
                    <div className="report-heading">
                      <h1 className="title">Frequently Asked</h1>
                      <h2>Questions</h2>
                      <h3 className="title">(FAQ)</h3>
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-12">
                    <div className="quetions-box">
                      <div className="f-Q">
                        <div className="year-holder">
                          <h1 className="title">2023</h1>
                        </div>
                        <div className="quetion-holder">
                          <h1 className="title">
                            AGM 2023 Questions & Answers
                          </h1>
                        </div>
                      </div>
                      <div className="purple-read-more">
                        <Button
                          className="purple-read-more-btn"
                          id="btn-size"
                          target="_Black"
                          href="https://ciplaqcil.co.ug/wp-content/uploads/2023/08/AGM-2023-questions.pdf"
                        >
                          Open
                        </Button>{" "}
                      </div>
                    </div>

                    <div className="quetions-box">
                      <div className="f-Q">
                        <div className="year-holder">
                          <h1 className="title">2022</h1>
                        </div>
                        <div className="quetion-holder">
                          <h1 className="title">
                            AGM 2022 Questions & Answers
                          </h1>
                        </div>
                      </div>
                      <div className="purple-read-more">
                        <Button
                          className="purple-read-more-btn"
                          id="btn-size"
                          target="_Black"
                          href="https://ciplaqcil.co.ug/wp-content/uploads/2022/09/AGM-2022-QUESTIONS-.pdf"
                        >
                          Open
                        </Button>{" "}
                      </div>
                    </div>

                    <div className="quetions-box">
                      <div className="f-Q">
                        <div className="year-holder">
                          <h1 className="title">2021</h1>
                        </div>
                        <div className="quetion-holder">
                          <h1 className="title">
                            AGM 2021 Questions & Answers
                          </h1>
                        </div>
                      </div>
                      <div className="purple-read-more">
                        <Button
                          className="purple-read-more-btn"
                          id="btn-size"
                          target="_Black"
                          href="https://ciplaqcil.co.ug/wp-content/uploads/2021/10/AGM-2021-Questions.pdf"
                        >
                          Open
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default FandQ;
